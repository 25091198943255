"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledPhotoSizeSelectLarge = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1lfq1050"
} : {
  target: "e1lfq1050",
  label: "StyledPhotoSizeSelectLarge"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL1Bob3RvU2l6ZVNlbGVjdExhcmdlLmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJNkMiLCJmaWxlIjoiLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvaWNvbnMvUGhvdG9TaXplU2VsZWN0TGFyZ2UuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkUGhvdG9TaXplU2VsZWN0TGFyZ2UgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBQaG90b1NpemVTZWxlY3RMYXJnZSA9ICh7IHNpemUgPSAnMS41cmVtJywgb25DbGljaywgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkUGhvdG9TaXplU2VsZWN0TGFyZ2VcbiAgICBjbGFzc05hbWU9XCJjb21wb25lbnRzLS1pY29uXCJcbiAgICBzaXplPXtzaXplfVxuICAgIGNsaWNrYWJsZT17dHlwZW9mIG9uQ2xpY2sgIT09ICd1bmRlZmluZWQnfVxuICAgIG9uQ2xpY2s9e29uQ2xpY2t9XG4gICAgey4uLnByb3BzfVxuICA+XG4gICAgPHN2ZyB2aWV3Qm94PVwiMCAwIDI0IDI0XCIgZmlsbD1cImN1cnJlbnRDb2xvclwiPlxuICAgICAgPHBhdGggZD1cIk0yMSAxNWgydjJoLTJ2LTJ6bTAtNGgydjJoLTJ2LTJ6bTIgOGgtMnYyYzEgMCAyLTEgMi0yek0xMyAzaDJ2MmgtMlYzem04IDRoMnYyaC0yVjd6bTAtNHYyaDJjMC0xLTEtMi0yLTJ6TTEgN2gydjJIMVY3em0xNi00aDJ2MmgtMlYzem0wIDE2aDJ2MmgtMnYtMnpNMyAzQzIgMyAxIDQgMSA1aDJWM3ptNiAwaDJ2Mkg5VjN6TTUgM2gydjJINVYzem0tNCA4djhjMCAxLjEuOSAyIDIgMmgxMlYxMUgxem0yIDhsMi41LTMuMjEgMS43OSAyLjE1IDIuNS0zLjIyTDEzIDE5SDN6XCIgLz5cbiAgICA8L3N2Zz5cbiAgPC9TdHlsZWRQaG90b1NpemVTZWxlY3RMYXJnZT5cbilcblxuZXhwb3J0IGRlZmF1bHQgUGhvdG9TaXplU2VsZWN0TGFyZ2VcbiJdfQ== */"));

var PhotoSizeSelectLarge = function PhotoSizeSelectLarge(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledPhotoSizeSelectLarge, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M21 15h2v2h-2v-2zm0-4h2v2h-2v-2zm2 8h-2v2c1 0 2-1 2-2zM13 3h2v2h-2V3zm8 4h2v2h-2V7zm0-4v2h2c0-1-1-2-2-2zM1 7h2v2H1V7zm16-4h2v2h-2V3zm0 16h2v2h-2v-2zM3 3C2 3 1 4 1 5h2V3zm6 0h2v2H9V3zM5 3h2v2H5V3zm-4 8v8c0 1.1.9 2 2 2h12V11H1zm2 8l2.5-3.21 1.79 2.15 2.5-3.22L13 19H3z"
  })));
};

var _default = PhotoSizeSelectLarge;
exports["default"] = _default;
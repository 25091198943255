"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _Avatar = _interopRequireDefault(require("./Avatar"));

var _ModalLink = _interopRequireDefault(require("./ModalLink"));

var _Panel = _interopRequireDefault(require("./Panel"));

var _react2 = require("@emotion/react");

var _excluded = ["team"];
var StyledContent = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1i1h050"
} : {
  target: "e1i1h050",
  label: "StyledContent"
})(".name{font-weight:", function (_ref) {
  var theme = _ref.theme;
  return theme.typography.normal.medium;
}, ";margin-bottom:1rem;}.team-score{margin-bottom:1.5rem;.label{font-size:0.9rem;margin-bottom:0.5rem;}}.members{display:flex;>*:not(:first-child){margin-left:-1rem;}}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL1RlYW1DYXJkLmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFRZ0MiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvVGVhbUNhcmQuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuaW1wb3J0IEF2YXRhciBmcm9tICcuL0F2YXRhcidcbmltcG9ydCBNb2RhbExpbmsgZnJvbSAnLi9Nb2RhbExpbmsnXG5pbXBvcnQgUGFuZWwgZnJvbSAnLi9QYW5lbCdcblxuY29uc3QgU3R5bGVkQ29udGVudCA9IHN0eWxlZC5kaXZgXG4gIC5uYW1lIHtcbiAgICBmb250LXdlaWdodDogJHsoeyB0aGVtZSB9KSA9PiB0aGVtZS50eXBvZ3JhcGh5Lm5vcm1hbC5tZWRpdW19O1xuICAgIG1hcmdpbi1ib3R0b206IDFyZW07XG4gIH1cblxuICAudGVhbS1zY29yZSB7XG4gICAgbWFyZ2luLWJvdHRvbTogMS41cmVtO1xuXG4gICAgLmxhYmVsIHtcbiAgICAgIGZvbnQtc2l6ZTogMC45cmVtO1xuICAgICAgbWFyZ2luLWJvdHRvbTogMC41cmVtO1xuICAgIH1cbiAgfVxuXG4gIC5tZW1iZXJzIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuXG4gICAgPiAqOm5vdCg6Zmlyc3QtY2hpbGQpIHtcbiAgICAgIG1hcmdpbi1sZWZ0OiAtMXJlbTtcbiAgICB9XG4gIH1cbmBcblxuY29uc3QgVGVhbUNhcmQgPSAoeyB0ZWFtLCAuLi5wcm9wcyB9KSA9PiAoXG4gIDxQYW5lbCB7Li4ucHJvcHN9IHNsaW0+XG4gICAgPFN0eWxlZENvbnRlbnQ+XG4gICAgICA8ZGl2IGNsYXNzTmFtZT1cIm5hbWVcIj5cbiAgICAgICAgPE1vZGFsTGluayB0bz17YC90ZWFtcy8ke3RlYW0uaWR9YH0+e3RlYW0ubmFtZX08L01vZGFsTGluaz5cbiAgICAgIDwvZGl2PlxuICAgICAgPGRpdiBjbGFzc05hbWU9XCJtZW1iZXJzXCI+XG4gICAgICAgIHt0ZWFtLm1lbWJlcnMubWFwKCh7IGlkOiBtZW1iZXJJZCwgZW1haWwsIGluaXRpYWxzIH0pID0+IChcbiAgICAgICAgICA8QXZhdGFyIHNpemU9XCJzbWFsbFwiIGtleT17bWVtYmVySWR9IGxhYmVsPXtpbml0aWFsc30gZW1haWw9e2VtYWlsfSAvPlxuICAgICAgICApKX1cbiAgICAgICAge3RlYW0ubWVtYmVycy5sZW5ndGggPT09IDAgJiYgPGRpdiBjbGFzc05hbWU9XCJlbXB0eVwiPk5vIG1lbWJlcnM8L2Rpdj59XG4gICAgICA8L2Rpdj5cbiAgICA8L1N0eWxlZENvbnRlbnQ+XG4gIDwvUGFuZWw+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IFRlYW1DYXJkXG4iXX0= */"));

var TeamCard = function TeamCard(_ref2) {
  var team = _ref2.team,
      props = (0, _objectWithoutProperties2["default"])(_ref2, _excluded);
  return (0, _react2.jsx)(_Panel["default"], (0, _extends2["default"])({}, props, {
    slim: true
  }), (0, _react2.jsx)(StyledContent, null, (0, _react2.jsx)("div", {
    className: "name"
  }, (0, _react2.jsx)(_ModalLink["default"], {
    to: "/teams/".concat(team.id)
  }, team.name)), (0, _react2.jsx)("div", {
    className: "members"
  }, team.members.map(function (_ref3) {
    var memberId = _ref3.id,
        email = _ref3.email,
        initials = _ref3.initials;
    return (0, _react2.jsx)(_Avatar["default"], {
      size: "small",
      key: memberId,
      label: initials,
      email: email
    });
  }), team.members.length === 0 && (0, _react2.jsx)("div", {
    className: "empty"
  }, "No members"))));
};

var _default = TeamCard;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledChevronLeft = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "ec8rdtp0"
} : {
  target: "ec8rdtp0",
  label: "StyledChevronLeft"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0NoZXZyb25MZWZ0LmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJb0MiLCJmaWxlIjoiLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvaWNvbnMvQ2hldnJvbkxlZnQuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkQ2hldnJvbkxlZnQgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBDaGV2cm9uTGVmdCA9ICh7IHNpemUgPSAnMS41cmVtJywgb25DbGljaywgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkQ2hldnJvbkxlZnRcbiAgICBjbGFzc05hbWU9XCJjb21wb25lbnRzLS1pY29uXCJcbiAgICBzaXplPXtzaXplfVxuICAgIGNsaWNrYWJsZT17dHlwZW9mIG9uQ2xpY2sgIT09ICd1bmRlZmluZWQnfVxuICAgIG9uQ2xpY2s9e29uQ2xpY2t9XG4gICAgey4uLnByb3BzfVxuICA+XG4gICAgPHN2ZyB2aWV3Qm94PVwiMCAwIDI0IDI0XCIgZmlsbD1cImN1cnJlbnRDb2xvclwiPlxuICAgICAgPHBhdGggZD1cIk0xNS40MSA3LjQxTDE0IDZsLTYgNiA2IDYgMS40MS0xLjQxTDEwLjgzIDEybDQuNTgtNC41OXpcIiAvPlxuICAgIDwvc3ZnPlxuICA8L1N0eWxlZENoZXZyb25MZWZ0PlxuKVxuXG5leHBvcnQgZGVmYXVsdCBDaGV2cm9uTGVmdFxuIl19 */"));

var ChevronLeft = function ChevronLeft(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledChevronLeft, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
  })));
};

var _default = ChevronLeft;
exports["default"] = _default;
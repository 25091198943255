"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _useAuth2 = require("../useAuth");

var _Login = _interopRequireDefault(require("./Login"));

var _react2 = require("@emotion/react");

var LoginRequired = function LoginRequired(_ref) {
  var children = _ref.children;

  var _useAuth = (0, _useAuth2.useAuth)(),
      authenticated = _useAuth.authenticated;

  if (!authenticated) {
    return (0, _react2.jsx)(_Login["default"], null);
  }

  return children;
};

var WrappedLoginRequired = function WrappedLoginRequired(_ref2) {
  var children = _ref2.children,
      baseURL = _ref2.baseURL;
  return (0, _react2.jsx)(_useAuth2.AuthProvider, {
    baseURL: baseURL
  }, (0, _react2.jsx)(LoginRequired, null, children));
};

var _default = WrappedLoginRequired;
exports["default"] = _default;
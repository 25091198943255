"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Form = void 0;
Object.defineProperty(exports, "useFormContext", {
  enumerable: true,
  get: function get() {
    return _reactHookForm.useFormContext;
  }
});

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactHookForm = require("react-hook-form");

var _nope = require("@hookform/resolvers/nope");

var _react = require("@emotion/react");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var Form = function Form(_ref) {
  var children = _ref.children,
      validationSchema = _ref.validationSchema;
  var methods = (0, _reactHookForm.useForm)(_objectSpread({}, validationSchema && {
    resolver: (0, _nope.nopeResolver)(validationSchema)
  }));
  return (0, _react.jsx)(_reactHookForm.FormProvider, methods, children);
};

exports.Form = Form;
Form.displayName = 'Form';
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _constants = require("../constants");

var getQuestionsBodies = function getQuestionsBodies(_ref) {
  var questions = _ref.questions,
      formData = _ref.formData;
  var questionsMetaData = questions.map(function (question) {
    var id = question.id,
        type = question.type,
        title = question.question,
        choices = question.choices,
        conditions = question.conditions;
    return {
      id: id,
      type: type,
      title: title,
      choices: choices,
      conditions: conditions
    };
  });
  var questionsBodies = questionsMetaData.map(function (_ref2) {
    var _formData$;

    var id = _ref2.id,
        type = _ref2.type,
        title = _ref2.title,
        choices = _ref2.choices,
        conditions = _ref2.conditions;
    var area = formData["".concat(id, "-area")];
    var question = formData["".concat(id, "-title")] || title;
    var questionChoices = ((_formData$ = formData["".concat(id, "-choices")]) === null || _formData$ === void 0 ? void 0 : _formData$.split(_constants.CHOICES_SEPARATOR)) || choices || [];
    return {
      id: id,
      area: area,
      choices: questionChoices,
      question: question,
      description: '',
      type: type,
      conditions: conditions,
      required: true,
      immutable: true
    };
  });
  return questionsBodies;
};

var _default = getQuestionsBodies;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Datapoints = exports.Datapoint = void 0;

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = require("@emotion/react");

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var Datapoints = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "ewfgir1"
} : {
  target: "ewfgir1",
  label: "Datapoints"
})(process.env.NODE_ENV === "production" ? {
  name: "1p1er0y",
  styles: "display:flex;flex-wrap:wrap;gap:1rem"
} : {
  name: "1p1er0y",
  styles: "display:flex;flex-wrap:wrap;gap:1rem",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0RhdGFwb2ludHMuanN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUU2QiIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9EYXRhcG9pbnRzLmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBEYXRhcG9pbnRzID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC13cmFwOiB3cmFwO1xuICBnYXA6IDFyZW07XG5gXG5cbmNvbnN0IERhdGFwb2ludENvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIGZsZXgtYmFzaXM6IGNhbGMoNTAlIC0gMC41cmVtKTtcbiAgbWluLXdpZHRoOiAxNTBweDtcblxuICAua2V5IHtcbiAgICBmb250LXdlaWdodDogNDAwO1xuICAgIG9wYWNpdHk6IDAuNjtcbiAgICBtYXJnaW4tYm90dG9tOiAwLjVyZW07XG4gIH1cblxuICAudmFsdWUge1xuICAgIGZvbnQtc2l6ZTogMS4xcmVtO1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG4gIH1cbmBcblxuY29uc3QgRGF0YXBvaW50ID0gKHsgbGFiZWwsIGNoaWxkcmVuIH0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8RGF0YXBvaW50Q29udGFpbmVyPlxuICAgICAge2xhYmVsICYmIDxwIGNsYXNzTmFtZT1cImtleVwiPntsYWJlbH08L3A+fVxuICAgICAgPGRpdiBjbGFzc05hbWU9XCJ2YWx1ZVwiPntjaGlsZHJlbn08L2Rpdj5cbiAgICA8L0RhdGFwb2ludENvbnRhaW5lcj5cbiAgKVxufVxuXG5leHBvcnQgeyBEYXRhcG9pbnQsIERhdGFwb2ludHMgfVxuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
exports.Datapoints = Datapoints;
var DatapointContainer = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "ewfgir0"
} : {
  target: "ewfgir0",
  label: "DatapointContainer"
})(process.env.NODE_ENV === "production" ? {
  name: "1qcc1b5",
  styles: "flex-basis:calc(50% - 0.5rem);min-width:150px;.key{font-weight:400;opacity:0.6;margin-bottom:0.5rem;}.value{font-size:1.1rem;font-weight:600;}"
} : {
  name: "1qcc1b5",
  styles: "flex-basis:calc(50% - 0.5rem);min-width:150px;.key{font-weight:400;opacity:0.6;margin-bottom:0.5rem;}.value{font-size:1.1rem;font-weight:600;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0RhdGFwb2ludHMuanN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVFxQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9EYXRhcG9pbnRzLmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBEYXRhcG9pbnRzID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC13cmFwOiB3cmFwO1xuICBnYXA6IDFyZW07XG5gXG5cbmNvbnN0IERhdGFwb2ludENvbnRhaW5lciA9IHN0eWxlZC5kaXZgXG4gIGZsZXgtYmFzaXM6IGNhbGMoNTAlIC0gMC41cmVtKTtcbiAgbWluLXdpZHRoOiAxNTBweDtcblxuICAua2V5IHtcbiAgICBmb250LXdlaWdodDogNDAwO1xuICAgIG9wYWNpdHk6IDAuNjtcbiAgICBtYXJnaW4tYm90dG9tOiAwLjVyZW07XG4gIH1cblxuICAudmFsdWUge1xuICAgIGZvbnQtc2l6ZTogMS4xcmVtO1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG4gIH1cbmBcblxuY29uc3QgRGF0YXBvaW50ID0gKHsgbGFiZWwsIGNoaWxkcmVuIH0pID0+IHtcbiAgcmV0dXJuIChcbiAgICA8RGF0YXBvaW50Q29udGFpbmVyPlxuICAgICAge2xhYmVsICYmIDxwIGNsYXNzTmFtZT1cImtleVwiPntsYWJlbH08L3A+fVxuICAgICAgPGRpdiBjbGFzc05hbWU9XCJ2YWx1ZVwiPntjaGlsZHJlbn08L2Rpdj5cbiAgICA8L0RhdGFwb2ludENvbnRhaW5lcj5cbiAgKVxufVxuXG5leHBvcnQgeyBEYXRhcG9pbnQsIERhdGFwb2ludHMgfVxuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var Datapoint = function Datapoint(_ref) {
  var label = _ref.label,
      children = _ref.children;
  return (0, _react.jsx)(DatapointContainer, null, label && (0, _react.jsx)("p", {
    className: "key"
  }, label), (0, _react.jsx)("div", {
    className: "value"
  }, children));
};

exports.Datapoint = Datapoint;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledRoomPreferences = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "ec82pkl0"
} : {
  target: "ec82pkl0",
  label: "StyledRoomPreferences"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL1Jvb21QcmVmZXJlbmNlcy5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSXdDIiwiZmlsZSI6Ii4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL1Jvb21QcmVmZXJlbmNlcy5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBTdHlsZWRSb29tUHJlZmVyZW5jZXMgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBSb29tUHJlZmVyZW5jZXMgPSAoeyBzaXplID0gJzEuNXJlbScsIG9uQ2xpY2ssIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZFJvb21QcmVmZXJlbmNlc1xuICAgIGNsYXNzTmFtZT1cImNvbXBvbmVudHMtLWljb25cIlxuICAgIHNpemU9e3NpemV9XG4gICAgY2xpY2thYmxlPXt0eXBlb2Ygb25DbGljayAhPT0gJ3VuZGVmaW5lZCd9XG4gICAgb25DbGljaz17b25DbGlja31cbiAgICB7Li4ucHJvcHN9XG4gID5cbiAgICA8c3ZnIHZpZXdCb3g9XCIwIDAgMjQgMjRcIiBmaWxsPVwiY3VycmVudENvbG9yXCI+XG4gICAgICA8cGF0aCBkPVwiTTE0IDExLjI2VjZoM3Y0aDJWNGgtNVYzSDV2MTZIM3YyaDkuMjZjLS43OS0xLjEzLTEuMjYtMi41MS0xLjI2LTQgMC0yLjM4IDEuMTktNC40NyAzLTUuNzR6TTEwIDExaDJ2MmgtMnYtMnptMTEuNjkgNS4zN2wxLjE0LTEtMS0xLjczLTEuNDUuNDljLS4zMi0uMjctLjY4LS40OC0xLjA4LS42M0wxOSAxMmgtMmwtLjMgMS40OWMtLjQuMTUtLjc2LjM2LTEuMDguNjNsLTEuNDUtLjQ5LTEgMS43MyAxLjE0IDFjLS4wOC41LS4wOC43NiAwIDEuMjZsLTEuMTQgMSAxIDEuNzMgMS40NS0uNDljLjMyLjI3LjY4LjQ4IDEuMDguNjNMMTcgMjJoMmwuMy0xLjQ5Yy40LS4xNS43Ni0uMzYgMS4wOC0uNjNsMS40NS40OSAxLTEuNzMtMS4xNC0xYy4wOC0uNTEuMDgtLjc3IDAtMS4yN3pNMTggMTljLTEuMSAwLTItLjktMi0ycy45LTIgMi0yIDIgLjkgMiAyLS45IDItMiAyelwiIC8+XG4gICAgPC9zdmc+XG4gIDwvU3R5bGVkUm9vbVByZWZlcmVuY2VzPlxuKVxuXG5leHBvcnQgZGVmYXVsdCBSb29tUHJlZmVyZW5jZXNcbiJdfQ== */"));

var RoomPreferences = function RoomPreferences(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledRoomPreferences, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M14 11.26V6h3v4h2V4h-5V3H5v16H3v2h9.26c-.79-1.13-1.26-2.51-1.26-4 0-2.38 1.19-4.47 3-5.74zM10 11h2v2h-2v-2zm11.69 5.37l1.14-1-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L19 12h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1c-.08.5-.08.76 0 1.26l-1.14 1 1 1.73 1.45-.49c.32.27.68.48 1.08.63L17 22h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1c.08-.51.08-.77 0-1.27zM18 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
  })));
};

var _default = RoomPreferences;
exports["default"] = _default;
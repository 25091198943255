import React from 'react'

import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import ThemeProvider from '@fwl/shared/components/ThemeProvider'

import AppRouter from './components/AppRouter'
import { StoreProvider } from './useStore'

const App = () => (
  <ThemeProvider>
    <StoreProvider baseURL={API_URL}>
      <Router>
        <AppRouter />
      </Router>
    </StoreProvider>
  </ThemeProvider>
)

render(<App />, document.getElementById('app'))

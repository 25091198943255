"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledSupport = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1xtmjp60"
} : {
  target: "e1xtmjp60",
  label: "StyledSupport"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL1N1cHBvcnQuanN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUlnQyIsImZpbGUiOiIuLi8uLi8uLi9zcmMvY29tcG9uZW50cy9pY29ucy9TdXBwb3J0LmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcblxuaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5cbmNvbnN0IFN0eWxlZFN1cHBvcnQgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBTdXBwb3J0ID0gKHsgc2l6ZSA9ICcxLjVyZW0nLCBvbkNsaWNrLCAuLi5wcm9wcyB9KSA9PiAoXG4gIDxTdHlsZWRTdXBwb3J0XG4gICAgY2xhc3NOYW1lPVwiY29tcG9uZW50cy0taWNvblwiXG4gICAgc2l6ZT17c2l6ZX1cbiAgICBjbGlja2FibGU9e3R5cGVvZiBvbkNsaWNrICE9PSAndW5kZWZpbmVkJ31cbiAgICBvbkNsaWNrPXtvbkNsaWNrfVxuICAgIHsuLi5wcm9wc31cbiAgPlxuICAgIDxzdmcgdmlld0JveD1cIjAgMCAyNCAyNFwiIGZpbGw9XCJjdXJyZW50Q29sb3JcIj5cbiAgICAgIDxwYXRoIGQ9XCJNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnptNy40NiA3LjEybC0yLjc4IDEuMTVjLS41MS0xLjM2LTEuNTgtMi40NC0yLjk1LTIuOTRsMS4xNS0yLjc4YzIuMS44IDMuNzcgMi40NyA0LjU4IDQuNTd6TTEyIDE1Yy0xLjY2IDAtMy0xLjM0LTMtM3MxLjM0LTMgMy0zIDMgMS4zNCAzIDMtMS4zNCAzLTMgM3pNOS4xMyA0LjU0bDEuMTcgMi43OGMtMS4zOC41LTIuNDcgMS41OS0yLjk4IDIuOTdMNC41NCA5LjEzYy44MS0yLjExIDIuNDgtMy43OCA0LjU5LTQuNTl6TTQuNTQgMTQuODdsMi43OC0xLjE1Yy41MSAxLjM4IDEuNTkgMi40NiAyLjk3IDIuOTZsLTEuMTcgMi43OGMtMi4xLS44MS0zLjc3LTIuNDgtNC41OC00LjU5em0xMC4zNCA0LjU5bC0xLjE1LTIuNzhjMS4zNy0uNTEgMi40NS0xLjU5IDIuOTUtMi45N2wyLjc4IDEuMTdjLS44MSAyLjEtMi40OCAzLjc3LTQuNTggNC41OHpcIiAvPlxuICAgIDwvc3ZnPlxuICA8L1N0eWxlZFN1cHBvcnQ+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IFN1cHBvcnRcbiJdfQ== */"));

var Support = function Support(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledSupport, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm7.46 7.12l-2.78 1.15c-.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78c2.1.8 3.77 2.47 4.58 4.57zM12 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zM9.13 4.54l1.17 2.78c-1.38.5-2.47 1.59-2.98 2.97L4.54 9.13c.81-2.11 2.48-3.78 4.59-4.59zM4.54 14.87l2.78-1.15c.51 1.38 1.59 2.46 2.97 2.96l-1.17 2.78c-2.1-.81-3.77-2.48-4.58-4.59zm10.34 4.59l-1.15-2.78c1.37-.51 2.45-1.59 2.95-2.97l2.78 1.17c-.81 2.1-2.48 3.77-4.58 4.58z"
  })));
};

var _default = Support;
exports["default"] = _default;
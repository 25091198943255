"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reactRouterDom = require("react-router-dom");

var useRenderLocation = function useRenderLocation() {
  var currentLocation = (0, _reactRouterDom.useLocation)();
  var isModal = [(0, _reactRouterDom.useRouteMatch)('/members/:id') !== null, (0, _reactRouterDom.useRouteMatch)('/launch/surveys/:id/preview') !== null, (0, _reactRouterDom.useRouteMatch)('/organization/surveys/:surveyId/presentation') !== null, (0, _reactRouterDom.useRouteMatch)('/teams/:teamId/surveys/:surveyId/presentation') !== null].some(function (val) {
    return val;
  }); // eslint-disable-next-line no-nested-ternary

  return currentLocation.state && currentLocation.state.location ? currentLocation.state.location : isModal ? {
    pathname: '/'
  } : currentLocation;
};

var _default = useRenderLocation;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["children"];

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var StyledHelp = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "efc0xo30"
} : {
  target: "efc0xo30",
  label: "StyledHelp"
})(process.env.NODE_ENV === "production" ? {
  name: "ysmbpz",
  styles: "border-radius:0.25rem;background:#f8f8f8;padding:1rem;display:flex;&:not(:last-child){margin-bottom:2rem;}.icon{flex:0;margin-right:0.5rem;opacity:0.5;}.content{flex:1;font-size:0.95rem;line-height:150%;}"
} : {
  name: "ysmbpz",
  styles: "border-radius:0.25rem;background:#f8f8f8;padding:1rem;display:flex;&:not(:last-child){margin-bottom:2rem;}.icon{flex:0;margin-right:0.5rem;opacity:0.5;}.content{flex:1;font-size:0.95rem;line-height:150%;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0hlbHAuanN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUk2QiIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9IZWxwLmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcblxuaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5cbmNvbnN0IFN0eWxlZEhlbHAgPSBzdHlsZWQuZGl2YFxuICBib3JkZXItcmFkaXVzOiAwLjI1cmVtO1xuICBiYWNrZ3JvdW5kOiAjZjhmOGY4O1xuICBwYWRkaW5nOiAxcmVtO1xuICBkaXNwbGF5OiBmbGV4O1xuXG4gICY6bm90KDpsYXN0LWNoaWxkKSB7XG4gICAgbWFyZ2luLWJvdHRvbTogMnJlbTtcbiAgfVxuXG4gIC5pY29uIHtcbiAgICBmbGV4OiAwO1xuICAgIG1hcmdpbi1yaWdodDogMC41cmVtO1xuICAgIG9wYWNpdHk6IDAuNTtcbiAgfVxuXG4gIC5jb250ZW50IHtcbiAgICBmbGV4OiAxO1xuICAgIGZvbnQtc2l6ZTogMC45NXJlbTtcbiAgICBsaW5lLWhlaWdodDogMTUwJTtcbiAgfVxuYFxuXG5jb25zdCBIZWxwID0gKHsgY2hpbGRyZW4sIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZEhlbHAgY2xhc3NOYW1lPVwiXCIgey4uLnByb3BzfT5cbiAgICA8ZGl2IGNsYXNzTmFtZT1cImljb25cIj5cbiAgICAgIDxzdmdcbiAgICAgICAgeG1sbnM9XCJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Z1wiXG4gICAgICAgIGhlaWdodD1cIjE4cHhcIlxuICAgICAgICB2aWV3Qm94PVwiMCAwIDI0IDI0XCJcbiAgICAgICAgd2lkdGg9XCIxOHB4XCJcbiAgICAgICAgZmlsbD1cImN1cnJlbnRDb2xvclwiXG4gICAgICA+XG4gICAgICAgIDxwYXRoIGQ9XCJNMCAwaDI0djI0SDB6XCIgZmlsbD1cIm5vbmVcIiAvPlxuICAgICAgICA8cGF0aCBkPVwiTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTEgMTVoLTJ2LTZoMnY2em0wLThoLTJWN2gydjJ6XCIgLz5cbiAgICAgIDwvc3ZnPlxuICAgIDwvZGl2PlxuICAgIDxkaXYgY2xhc3NOYW1lPVwiY29udGVudFwiPntjaGlsZHJlbn08L2Rpdj5cbiAgPC9TdHlsZWRIZWxwPlxuKVxuXG5IZWxwLmRpc3BsYXlOYW1lID0gJ0hlbHAnXG5cbmV4cG9ydCBkZWZhdWx0IEhlbHBcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var Help = function Help(_ref) {
  var children = _ref.children,
      props = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return (0, _react2.jsx)(StyledHelp, (0, _extends2["default"])({
    className: ""
  }, props), (0, _react2.jsx)("div", {
    className: "icon"
  }, (0, _react2.jsx)("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: "18px",
    viewBox: "0 0 24 24",
    width: "18px",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  }), (0, _react2.jsx)("path", {
    d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
  }))), (0, _react2.jsx)("div", {
    className: "content"
  }, children));
};

Help.displayName = 'Help';
var _default = Help;
exports["default"] = _default;
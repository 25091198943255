"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["value", "max", "color"];
var StyledBar = (0, _base["default"])("nav", process.env.NODE_ENV === "production" ? {
  target: "e10vf9hv0"
} : {
  target: "e10vf9hv0",
  label: "StyledBar"
})("height:8px;width:100%;border-radius:4px;background:", function (_ref) {
  var theme = _ref.theme;
  return theme.light.fade1;
}, ";&:after{border-radius:0.2rem;display:block;content:'';height:100%;width:", function (_ref2) {
  var percentage = _ref2.percentage;
  return "".concat(percentage, "%");
}, ";background:", function (_ref3) {
  var theme = _ref3.theme,
      color = _ref3.color;
  return theme.palette[color].base;
}, ";}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0Jhci5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJNEIiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvQmFyLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkQmFyID0gc3R5bGVkLm5hdmBcbiAgaGVpZ2h0OiA4cHg7XG4gIHdpZHRoOiAxMDAlO1xuICBib3JkZXItcmFkaXVzOiA0cHg7XG4gIGJhY2tncm91bmQ6ICR7KHsgdGhlbWUgfSkgPT4gdGhlbWUubGlnaHQuZmFkZTF9O1xuXG4gICY6YWZ0ZXIge1xuICAgIGJvcmRlci1yYWRpdXM6IDAuMnJlbTtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBjb250ZW50OiAnJztcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgd2lkdGg6ICR7KHsgcGVyY2VudGFnZSB9KSA9PiBgJHtwZXJjZW50YWdlfSVgfTtcbiAgICBiYWNrZ3JvdW5kOiAkeyh7IHRoZW1lLCBjb2xvciB9KSA9PiB0aGVtZS5wYWxldHRlW2NvbG9yXS5iYXNlfTtcbiAgfVxuYFxuXG5jb25zdCBCYXIgPSAoeyB2YWx1ZSA9IDAsIG1heCA9IDEwMCwgY29sb3IgPSAnZ3JlZW4nLCAuLi5wcm9wcyB9KSA9PiB7XG4gIGNvbnN0IHBlcmNlbnRhZ2UgPSBNYXRoLm1pbigxMDAsICh2YWx1ZSAvIG1heCkgKiAxMDApXG5cbiAgcmV0dXJuIChcbiAgICA8U3R5bGVkQmFyXG4gICAgICBwZXJjZW50YWdlPXtwZXJjZW50YWdlfVxuICAgICAgY29sb3I9e2NvbG9yfVxuICAgICAgY2xhc3NOYW1lPVwiY29tcG9uZW50LS1iYXJcIlxuICAgICAgey4uLnByb3BzfVxuICAgID5cbiAgICAgIDxkaXYgY2xhc3NOYW1lPVwiYmFyXCIgLz5cbiAgICA8L1N0eWxlZEJhcj5cbiAgKVxufVxuXG5leHBvcnQgZGVmYXVsdCBCYXJcbiJdfQ== */"));

var Bar = function Bar(_ref4) {
  var _ref4$value = _ref4.value,
      value = _ref4$value === void 0 ? 0 : _ref4$value,
      _ref4$max = _ref4.max,
      max = _ref4$max === void 0 ? 100 : _ref4$max,
      _ref4$color = _ref4.color,
      color = _ref4$color === void 0 ? 'green' : _ref4$color,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  var percentage = Math.min(100, value / max * 100);
  return (0, _react2.jsx)(StyledBar, (0, _extends2["default"])({
    percentage: percentage,
    color: color,
    className: "component--bar"
  }, props), (0, _react2.jsx)("div", {
    className: "bar"
  }));
};

var _default = Bar;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _Icon = _interopRequireDefault(require("./Icon"));

var _Tooltip = _interopRequireDefault(require("./Tooltip"));

var _react2 = require("@emotion/react");

var _excluded = ["content"];

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var StyledQuickHelp = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1m1di6w0"
} : {
  target: "e1m1di6w0",
  label: "StyledQuickHelp"
})(process.env.NODE_ENV === "production" ? {
  name: "1djms1s",
  styles: ".components--icon{opacity:0.4;}"
} : {
  name: "1djms1s",
  styles: ".components--icon{opacity:0.4;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL1F1aWNrSGVscC5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBT2tDIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL1F1aWNrSGVscC5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5pbXBvcnQgSWNvbiBmcm9tICcuL0ljb24nXG5pbXBvcnQgVG9vbHRpcCBmcm9tICcuL1Rvb2x0aXAnXG5cbmNvbnN0IFN0eWxlZFF1aWNrSGVscCA9IHN0eWxlZC5kaXZgXG4gIC5jb21wb25lbnRzLS1pY29uIHtcbiAgICBvcGFjaXR5OiAwLjQ7XG4gIH1cbmBcblxuY29uc3QgUXVpY2tIZWxwID0gKHsgY29udGVudCwgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkUXVpY2tIZWxwIGNsYXNzTmFtZT1cImZhYnJpYy0tcXVpY2staGVscFwiIHsuLi5wcm9wc30+XG4gICAgPFRvb2x0aXAgY29udGVudD17Y29udGVudH0+XG4gICAgICA8SWNvbiBpY29uPVwiaW5mb1wiIHNpemU9XCIxLjRyZW1cIiAvPlxuICAgIDwvVG9vbHRpcD5cbiAgPC9TdHlsZWRRdWlja0hlbHA+XG4pXG5cblF1aWNrSGVscC5kaXNwbGF5TmFtZSA9ICdRdWlja0hlbHAnXG5cbmV4cG9ydCBkZWZhdWx0IFF1aWNrSGVscFxuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var QuickHelp = function QuickHelp(_ref) {
  var content = _ref.content,
      props = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return (0, _react2.jsx)(StyledQuickHelp, (0, _extends2["default"])({
    className: "fabric--quick-help"
  }, props), (0, _react2.jsx)(_Tooltip["default"], {
    content: content
  }, (0, _react2.jsx)(_Icon["default"], {
    icon: "info",
    size: "1.4rem"
  })));
};

QuickHelp.displayName = 'QuickHelp';
var _default = QuickHelp;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["label", "children"];

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var StyledField = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1ltt3te0"
} : {
  target: "e1ltt3te0",
  label: "StyledField"
})(process.env.NODE_ENV === "production" ? {
  name: "30f1s5",
  styles: "display:block;&:not(:last-child){margin-bottom:1.5rem;}>label .label{font-weight:500;margin-bottom:0.5rem;}"
} : {
  name: "30f1s5",
  styles: "display:block;&:not(:last-child){margin-bottom:1.5rem;}>label .label{font-weight:500;margin-bottom:0.5rem;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0ZpZWxkLmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJOEIiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvRmllbGQuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkRmllbGQgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBibG9jaztcblxuICAmOm5vdCg6bGFzdC1jaGlsZCkge1xuICAgIG1hcmdpbi1ib3R0b206IDEuNXJlbTtcbiAgfVxuXG4gID4gbGFiZWwgLmxhYmVsIHtcbiAgICBmb250LXdlaWdodDogNTAwO1xuICAgIG1hcmdpbi1ib3R0b206IDAuNXJlbTtcbiAgfVxuYFxuXG5jb25zdCBGaWVsZCA9ICh7IGxhYmVsLCBjaGlsZHJlbiwgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkRmllbGQgY2xhc3NOYW1lPVwiY29tcG9uZW50LS1maWVsZFwiIHsuLi5wcm9wc30+XG4gICAgPGxhYmVsPlxuICAgICAgPGRpdiBjbGFzc05hbWU9XCJsYWJlbFwiPntsYWJlbH08L2Rpdj5cbiAgICAgIDxkaXYgY2xhc3NOYW1lPVwiY2hpbGRyZW5cIj57Y2hpbGRyZW59PC9kaXY+XG4gICAgPC9sYWJlbD5cbiAgPC9TdHlsZWRGaWVsZD5cbilcblxuRmllbGQuZGlzcGxheU5hbWUgPSAnRmllbGQnXG5cbmV4cG9ydCBkZWZhdWx0IEZpZWxkXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var Field = function Field(_ref) {
  var label = _ref.label,
      children = _ref.children,
      props = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return (0, _react2.jsx)(StyledField, (0, _extends2["default"])({
    className: "component--field"
  }, props), (0, _react2.jsx)("label", null, (0, _react2.jsx)("div", {
    className: "label"
  }, label), (0, _react2.jsx)("div", {
    className: "children"
  }, children)));
};

Field.displayName = 'Field';
var _default = Field;
exports["default"] = _default;
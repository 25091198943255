import styled from '@emotion/styled'

const Container = styled.div`
  font-size: 0.8rem;
  margin-top: 3rem;
  text-align: center;
  color: ${({ theme }) => theme.root.fade5};
`

const AnonymityMessage = () => {
  return (
    <Container>
      <p>Your answers are anonymous</p>
    </Container>
  )
}

export default AnonymityMessage

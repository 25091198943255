"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["children"];
var StyledLeftBox = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "epqtiaq0"
} : {
  target: "epqtiaq0",
  label: "StyledLeftBox"
})("display:grid;grid-template-columns:repeat(6, 1fr);grid-gap:", function (_ref) {
  var theme = _ref.theme;
  return theme.spacing.normal;
}, ";grid-template-areas:'primary primary secondary secondary secondary secondary';margin-bottom:", function (_ref2) {
  var theme = _ref2.theme;
  return theme.spacing.normal;
}, ";>*:first-child{grid-area:primary;}>*:not(:first-child){grid-area:secondary;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0xlZnRCb3guanN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUlnQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9MZWZ0Qm94LmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcblxuaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5cbmNvbnN0IFN0eWxlZExlZnRCb3ggPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBncmlkO1xuICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCg2LCAxZnIpO1xuICBncmlkLWdhcDogJHsoeyB0aGVtZSB9KSA9PiB0aGVtZS5zcGFjaW5nLm5vcm1hbH07XG4gIGdyaWQtdGVtcGxhdGUtYXJlYXM6ICdwcmltYXJ5IHByaW1hcnkgc2Vjb25kYXJ5IHNlY29uZGFyeSBzZWNvbmRhcnkgc2Vjb25kYXJ5JztcbiAgbWFyZ2luLWJvdHRvbTogJHsoeyB0aGVtZSB9KSA9PiB0aGVtZS5zcGFjaW5nLm5vcm1hbH07XG5cbiAgPiAqOmZpcnN0LWNoaWxkIHtcbiAgICBncmlkLWFyZWE6IHByaW1hcnk7XG4gIH1cblxuICA+ICo6bm90KDpmaXJzdC1jaGlsZCkge1xuICAgIGdyaWQtYXJlYTogc2Vjb25kYXJ5O1xuICB9XG5gXG5cbmNvbnN0IExlZnRCb3ggPSAoeyBjaGlsZHJlbiwgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkTGVmdEJveCBjbGFzc05hbWU9XCJjb21wb25lbnRzLS1sZWZ0Ym94XCIgey4uLnByb3BzfT5cbiAgICB7Y2hpbGRyZW59XG4gIDwvU3R5bGVkTGVmdEJveD5cbilcblxuTGVmdEJveC5kaXNwbGF5TmFtZSA9ICdMZWZ0Qm94J1xuXG5leHBvcnQgZGVmYXVsdCBMZWZ0Qm94XG4iXX0= */"));

var LeftBox = function LeftBox(_ref3) {
  var children = _ref3.children,
      props = (0, _objectWithoutProperties2["default"])(_ref3, _excluded);
  return (0, _react2.jsx)(StyledLeftBox, (0, _extends2["default"])({
    className: "components--leftbox"
  }, props), children);
};

LeftBox.displayName = 'LeftBox';
var _default = LeftBox;
exports["default"] = _default;
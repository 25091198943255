"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledAcUnit = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e7k65va0"
} : {
  target: "e7k65va0",
  label: "StyledAcUnit"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0FjVW5pdC5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSStCIiwiZmlsZSI6Ii4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0FjVW5pdC5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBTdHlsZWRBY1VuaXQgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBBY1VuaXQgPSAoeyBzaXplID0gJzEuNXJlbScsIG9uQ2xpY2ssIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZEFjVW5pdFxuICAgIGNsYXNzTmFtZT1cImNvbXBvbmVudHMtLWljb25cIlxuICAgIHNpemU9e3NpemV9XG4gICAgY2xpY2thYmxlPXt0eXBlb2Ygb25DbGljayAhPT0gJ3VuZGVmaW5lZCd9XG4gICAgb25DbGljaz17b25DbGlja31cbiAgICB7Li4ucHJvcHN9XG4gID5cbiAgICA8c3ZnIHZpZXdCb3g9XCIwIDAgMjQgMjRcIiBmaWxsPVwiY3VycmVudENvbG9yXCI+XG4gICAgICA8cGF0aCBkPVwiTTIyIDExaC00LjE3bDMuMjQtMy4yNC0xLjQxLTEuNDJMMTUgMTFoLTJWOWw0LjY2LTQuNjYtMS40Mi0xLjQxTDEzIDYuMTdWMmgtMnY0LjE3TDcuNzYgMi45MyA2LjM0IDQuMzQgMTEgOXYySDlMNC4zNCA2LjM0IDIuOTMgNy43NiA2LjE3IDExSDJ2Mmg0LjE3bC0zLjI0IDMuMjQgMS40MSAxLjQyTDkgMTNoMnYybC00LjY2IDQuNjYgMS40MiAxLjQxTDExIDE3LjgzVjIyaDJ2LTQuMTdsMy4yNCAzLjI0IDEuNDItMS40MUwxMyAxNXYtMmgybDQuNjYgNC42NiAxLjQxLTEuNDJMMTcuODMgMTNIMjJ6XCIgLz5cbiAgICA8L3N2Zz5cbiAgPC9TdHlsZWRBY1VuaXQ+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IEFjVW5pdFxuIl19 */"));

var AcUnit = function AcUnit(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledAcUnit, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M22 11h-4.17l3.24-3.24-1.41-1.42L15 11h-2V9l4.66-4.66-1.42-1.41L13 6.17V2h-2v4.17L7.76 2.93 6.34 4.34 11 9v2H9L4.34 6.34 2.93 7.76 6.17 11H2v2h4.17l-3.24 3.24 1.41 1.42L9 13h2v2l-4.66 4.66 1.42 1.41L11 17.83V22h2v-4.17l3.24 3.24 1.42-1.41L13 15v-2h2l4.66 4.66 1.41-1.42L17.83 13H22z"
  })));
};

var _default = AcUnit;
exports["default"] = _default;
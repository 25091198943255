"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

var _useRenderLocation = _interopRequireDefault(require("../useRenderLocation"));

var _react2 = require("@emotion/react");

var _excluded = ["to"];

var ModalLink = function ModalLink(_ref) {
  var to = _ref.to,
      props = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  var location = (0, _useRenderLocation["default"])();
  return (0, _react2.jsx)(_reactRouterDom.NavLink, (0, _extends2["default"])({
    to: {
      pathname: to,
      state: {
        location: location
      }
    }
  }, props));
};

var _default = ModalLink;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var useMultiStep = function useMultiStep(steps) {
  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      currentStep = _useState2[0],
      setCurrentStep = _useState2[1];

  var isLastStep = currentStep === steps.length - 1;
  var isFirstStep = currentStep === 0;
  var goToNextStep = (0, _react.useCallback)(function () {
    if (!isLastStep) {
      setCurrentStep(function (currentStep) {
        return currentStep + 1;
      });
    }
  }, [isLastStep]);
  var goToPrevStep = (0, _react.useCallback)(function () {
    if (!isFirstStep) {
      setCurrentStep(function (currentStep) {
        return currentStep - 1;
      });
    }
  }, [isFirstStep]);

  var goToStep = function goToStep(stepIndex) {
    return setCurrentStep(stepIndex);
  };

  var resetSteps = function resetSteps() {
    goToStep(0);
  };

  return {
    goToNextStep: goToNextStep,
    goToPrevStep: goToPrevStep,
    resetSteps: resetSteps,
    currentStep: currentStep,
    isLastStep: isLastStep,
    isFirstStep: isFirstStep
  };
};

var _default = useMultiStep;
exports["default"] = _default;
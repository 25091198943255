"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledBrightness7 = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "es4pf2r0"
} : {
  target: "es4pf2r0",
  label: "StyledBrightness7"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0JyaWdodG5lc3M3LmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJb0MiLCJmaWxlIjoiLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvaWNvbnMvQnJpZ2h0bmVzczcuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkQnJpZ2h0bmVzczcgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBCcmlnaHRuZXNzNyA9ICh7IHNpemUgPSAnMS41cmVtJywgb25DbGljaywgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkQnJpZ2h0bmVzczdcbiAgICBjbGFzc05hbWU9XCJjb21wb25lbnRzLS1pY29uXCJcbiAgICBzaXplPXtzaXplfVxuICAgIGNsaWNrYWJsZT17dHlwZW9mIG9uQ2xpY2sgIT09ICd1bmRlZmluZWQnfVxuICAgIG9uQ2xpY2s9e29uQ2xpY2t9XG4gICAgey4uLnByb3BzfVxuICA+XG4gICAgPHN2ZyB2aWV3Qm94PVwiMCAwIDI0IDI0XCIgZmlsbD1cImN1cnJlbnRDb2xvclwiPlxuICAgICAgPHBhdGggZD1cIk0yMCA4LjY5VjRoLTQuNjlMMTIgLjY5IDguNjkgNEg0djQuNjlMLjY5IDEyIDQgMTUuMzFWMjBoNC42OUwxMiAyMy4zMSAxNS4zMSAyMEgyMHYtNC42OUwyMy4zMSAxMiAyMCA4LjY5ek0xMiAxOGMtMy4zMSAwLTYtMi42OS02LTZzMi42OS02IDYtNiA2IDIuNjkgNiA2LTIuNjkgNi02IDZ6bTAtMTBjLTIuMjEgMC00IDEuNzktNCA0czEuNzkgNCA0IDQgNC0xLjc5IDQtNC0xLjc5LTQtNC00elwiIC8+XG4gICAgPC9zdmc+XG4gIDwvU3R5bGVkQnJpZ2h0bmVzczc+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IEJyaWdodG5lc3M3XG4iXX0= */"));

var Brightness7 = function Brightness7(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledBrightness7, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"
  })));
};

var _default = Brightness7;
exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  spacing: {
    normal: '1.5rem',
    breakpoints: [0, 576, 768, 992, 1200, 1440, 1920]
  },
  typography: {
    normal: {
      family: 'IBM Plex Sans',
      light: 400,
      normal: 400,
      medium: 500,
      bold: 600
    },
    display: {
      family: 'Source Sans Pro',
      light: 400,
      normal: 400,
      medium: 600,
      bold: 700
    }
  },
  primary: {
    // base: '#23234a',
    // base: '#2c3036',
    // contrast: '#ffffff',
    // fade1: '#ffffff11', // divider
    // fade2: '#ffffff22', // border
    // fade3: '#ffffff33', // button, panel bg
    // fade4: '#ffffff44', // active button, input borders
    // fade5: '#ffffff99', // faded text
    // shade1: '#00000005', // dark backplate
    // shade2: '#00000011', // darker backplate
    // base: '#fafaf9',
    base: '#232c37',
    contrast: '#ffffff',
    fade1: '#FFFFFF15',
    // divider
    fade2: '#FFFFFF10',
    // border
    fade3: '#FFFFFF33',
    // button, panel bg
    fade4: '#ffffff44',
    // active button, input borders
    fade5: '#ffffff99',
    // faded text
    shade1: '#00000005',
    // dark backplate
    shade2: '#00000011' // darker backplate

  },
  secondary: {
    // base: '#5b52f9',
    // base: '#645dde',
    // base: '#403d34',
    // base: '#51575c',
    // base: '#9c9995',
    base: '#19816c',
    // base: '#f2f2f2',
    // contrast: '#232c37',
    // base: '#0076aa',
    // base: '#197afa',
    // base: '#f16a4a',
    contrast: '#ffffff',
    fade1: '#ffffff11',
    // divider
    fade2: '#ffffff22',
    // border
    fade3: '#ffffff33',
    // button, panel bg
    fade4: '#ffffff44',
    // active button, input borders
    fade5: '#ffffff77' // faded text

  },
  tertiary: {
    base: '#45bea4',
    contrast: '#ffffff',
    fade1: '#ffffff11',
    // divider
    fade2: '#ffffff22',
    // border
    fade3: '#ffffff33',
    // button, panel bg
    fade4: '#ffffff44',
    // active button, input borders
    fade5: '#ffffff77' // faded text

  },
  root: {
    base: '#ffffff',
    contrast: '#232c37',
    fade1: '#12162111',
    fade2: '#12162122',
    fade3: '#12162133',
    fade4: '#12162144',
    fade5: '#12162177'
  },
  light: {
    base: '#ffffff',
    contrast: '#232c37',
    fade1: '#12162111',
    fade2: '#12162118',
    fade3: '#12162122',
    fade4: '#12162144',
    fade5: '#12162177'
  },
  dark: {
    // base: '#f6f6f6',
    // base: '#f7f7f5',
    // base: '#fafaf9',
    // base: '#fcfaf7',
    // base: '#f9f9f9',
    // base: '#f5f4f2',
    // base: '#ffffff',
    // base: '#f6f6f6',
    base: '#f7f6f5',
    contrast: '#232c37',
    fade1: '#12162111',
    fade2: '#12162122',
    fade3: '#12162133',
    fade4: '#12162144',
    fade5: '#12162155'
  },
  // https://learnui.design/tools/data-color-picker.html
  scale: [// Muted
  // '#f77448',
  // '#ff8d70',
  // '#ffbe4d',
  // '#80bf8a',
  // '#57ad71',
  // Vibrant
  // '#eb4731',
  // '#fa715f',
  // '#ffc74d',
  // '#76e38c',
  // '#0ec970',
  // New
  // '#de5f53',
  // '#ed8e5f',
  // '#c7edaf',
  // '#a1e398',
  // '#76c893',
  // X
  // '#fa2343',
  // '#fa6178',
  // '#85d6a1',
  // '#53cf7f',
  // '#30ba61',
  // Y https://1.bp.blogspot.com/-8ZL52gW0kOw/X8OYrUc9DUI/AAAAAAAACzg/5YfAfSNoXmgeE2zwdF0b8opQnQ6ympAgQCLcBGAsYHQ/s16000/GYR%2BOutput.PNG
  // '#f8696b',
  // '#fb9774',
  // '#fed580',
  // '#97cd7e',
  // '#63be7b',
  // Muted + Y
  // '#f8696b',
  // '#fb9774',
  // '#ffbe4d',
  // '#80bf8a',
  // '#57ad71',
  // Branding
  '#d41d64', '#e04380', '#ebb525', '#49c49b', '#19816c' // Peacon
  // '#ff735f',
  // '#fc9788',
  // '#fcc138',
  // '#4edea4',
  // '#03c376',
  ],
  palette: {
    red: {
      // base: '#ed7168',
      // base: '#ed415c',
      // base: '#ff4349',
      // base: '#f77448',
      base: '#d41d64',
      contrast: '#ffffff'
    },
    orange: {
      base: '#ebb525',
      contrast: '#000000'
    },
    yellow: {
      base: '#ffda73',
      contrast: '#000000'
    },
    lightRed: {
      base: '#f2e3e1',
      contrast: '#de938a'
    },
    blue: {
      base: '#4395de',
      contrast: '#ffffff'
    },
    lightBlue: {
      base: '#e8f2fb',
      contrast: '#195991'
    },
    green: {
      base: '#19816c',
      // base: '#57ad71',
      contrast: '#ffffff'
    },
    cyan: {
      base: '#49c49b',
      contrast: '#ffffff'
    },
    teal: {
      base: '#19816c',
      contrast: '#ffffff'
    },
    gray: {
      base: '#bbbbbb',
      contrast: '#ffffff'
    },
    lightGreen: {
      base: '#e1f9f4',
      contrast: '#21a88c'
    },
    magenta: {
      base: '#db58d9',
      contrast: '#ffffff'
    },
    lightMagenta: {
      base: '#f7f0f7',
      contrast: '#e899e8'
    }
  }
};
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledDownload = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "eya6zo50"
} : {
  target: "eya6zo50",
  label: "StyledDownload"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0Rvd25sb2FkLmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJaUMiLCJmaWxlIjoiLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvaWNvbnMvRG93bmxvYWQuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkRG93bmxvYWQgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBEb3dubG9hZCA9ICh7IHNpemUgPSAnMS41cmVtJywgb25DbGljaywgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkRG93bmxvYWRcbiAgICBjbGFzc05hbWU9XCJjb21wb25lbnRzLS1pY29uXCJcbiAgICBzaXplPXtzaXplfVxuICAgIGNsaWNrYWJsZT17dHlwZW9mIG9uQ2xpY2sgIT09ICd1bmRlZmluZWQnfVxuICAgIG9uQ2xpY2s9e29uQ2xpY2t9XG4gICAgey4uLnByb3BzfVxuICA+XG4gICAgPHN2ZyB2aWV3Qm94PVwiMCAwIDI0IDI0XCIgZmlsbD1cImN1cnJlbnRDb2xvclwiPlxuICAgICAgPHBhdGhcbiAgICAgICAgZmlsbFJ1bGU9XCJldmVub2RkXCJcbiAgICAgICAgZD1cIk0xNyA5VjdoMWEzIDMgMCAwIDEgMyAzdjhhMyAzIDAgMCAxLTMgM0g2YTMgMyAwIDAgMS0zLTN2LThhMyAzIDAgMCAxIDMtM2gxdjJINmExIDEgMCAwIDAtMSAxdjhhMSAxIDAgMCAwIDEgMWgxMmExIDEgMCAwIDAgMS0xdi04YTEgMSAwIDAgMC0xLTFoLTFabS0zLjk1NSAyLjA0NyAxLjE3MS0xLjE1N2ExLjA1NCAxLjA1NCAwIDAgMSAxLjQ3OCAwIDEuMDI0IDEuMDI0IDAgMCAxIDAgMS40NkwxMiAxNWwtMy42OTQtMy42NWExLjAyNCAxLjAyNCAwIDAgMSAwLTEuNDYgMS4wNTQgMS4wNTQgMCAwIDEgMS40NzggMGwxLjE3MSAxLjE1N1YzLjAzMkMxMC45NTUgMi40NjIgMTEuNDIzIDIgMTIgMnMxLjA0NS40NjIgMS4wNDUgMS4wMzJ2OC4wMTVaXCJcbiAgICAgIC8+XG4gICAgPC9zdmc+XG4gIDwvU3R5bGVkRG93bmxvYWQ+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IERvd25sb2FkXG4iXX0= */"));

var Download = function Download(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledDownload, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    fillRule: "evenodd",
    d: "M17 9V7h1a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3h1v2H6a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-1Zm-3.955 2.047 1.171-1.157a1.054 1.054 0 0 1 1.478 0 1.024 1.024 0 0 1 0 1.46L12 15l-3.694-3.65a1.024 1.024 0 0 1 0-1.46 1.054 1.054 0 0 1 1.478 0l1.171 1.157V3.032C10.955 2.462 11.423 2 12 2s1.045.462 1.045 1.032v8.015Z"
  })));
};

var _default = Download;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var defaultContext = {
  ACCOUNT: 'Helicon',
  NAME: 'Maria',
  TEAM: 'Data Science'
};

var renderTemplateString = function renderTemplateString(str) {
  var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultContext;
  return Object.entries(context).reduce(function (templateString, _ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return templateString.replace("%".concat(key.toUpperCase(), "%"), value);
  }, str);
};

var _default = renderTemplateString;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledBurstMode = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1s4e5mv0"
} : {
  target: "e1s4e5mv0",
  label: "StyledBurstMode"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0J1cnN0TW9kZS5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSWtDIiwiZmlsZSI6Ii4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0J1cnN0TW9kZS5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBTdHlsZWRCdXJzdE1vZGUgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBCdXJzdE1vZGUgPSAoeyBzaXplID0gJzEuNXJlbScsIG9uQ2xpY2ssIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZEJ1cnN0TW9kZVxuICAgIGNsYXNzTmFtZT1cImNvbXBvbmVudHMtLWljb25cIlxuICAgIHNpemU9e3NpemV9XG4gICAgY2xpY2thYmxlPXt0eXBlb2Ygb25DbGljayAhPT0gJ3VuZGVmaW5lZCd9XG4gICAgb25DbGljaz17b25DbGlja31cbiAgICB7Li4ucHJvcHN9XG4gID5cbiAgICA8c3ZnIHZpZXdCb3g9XCIwIDAgMjQgMjRcIiBmaWxsPVwiY3VycmVudENvbG9yXCI+XG4gICAgICA8cGF0aCBkPVwiTTEgNWgydjE0SDF6bTQgMGgydjE0SDV6bTE3IDBIMTBjLS41NSAwLTEgLjQ1LTEgMXYxMmMwIC41NS40NSAxIDEgMWgxMmMuNTUgMCAxLS40NSAxLTFWNmMwLS41NS0uNDUtMS0xLTF6TTExIDE3bDIuNS0zLjE1TDE1LjI5IDE2bDIuNS0zLjIyTDIxIDE3SDExelwiIC8+XG4gICAgPC9zdmc+XG4gIDwvU3R5bGVkQnVyc3RNb2RlPlxuKVxuXG5leHBvcnQgZGVmYXVsdCBCdXJzdE1vZGVcbiJdfQ== */"));

var BurstMode = function BurstMode(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledBurstMode, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zM11 17l2.5-3.15L15.29 16l2.5-3.22L21 17H11z"
  })));
};

var _default = BurstMode;
exports["default"] = _default;
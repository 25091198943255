"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaObject = exports.Media = exports.Content = void 0;

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _utils = require("../utils");

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var Media = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "en55jn2"
} : {
  target: "en55jn2",
  label: "Media"
})("max-width:100%;img,svg{max-width:100%;}", (0, _utils.mq)(_utils.BREAKPOINTS.sm), "{max-width:30%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL01lZGlhT2JqZWN0LmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJd0IiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvTWVkaWFPYmplY3QuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5cbmltcG9ydCB7IEJSRUFLUE9JTlRTLCBtcSB9IGZyb20gJy4uL3V0aWxzJ1xuXG5jb25zdCBNZWRpYSA9IHN0eWxlZC5kaXZgXG4gIG1heC13aWR0aDogMTAwJTtcblxuICBpbWcsXG4gIHN2ZyB7XG4gICAgbWF4LXdpZHRoOiAxMDAlO1xuICB9XG5cbiAgJHttcShCUkVBS1BPSU5UUy5zbSl9IHtcbiAgICBtYXgtd2lkdGg6IDMwJTtcbiAgfVxuYFxuXG5jb25zdCBDb250ZW50ID0gc3R5bGVkLmRpdmBcbiAgZmxleDogMTtcbiAgd2lkdGg6IDEwMCU7XG5gXG5cbmNvbnN0IE1lZGlhT2JqZWN0ID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGZsZXgtc3RhcnQ7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGdhcDogMXJlbTtcblxuICAke21xKEJSRUFLUE9JTlRTLnNtKX0ge1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBnYXA6IDJyZW07XG4gIH1cbmBcblxuZXhwb3J0IHsgQ29udGVudCwgTWVkaWEsIE1lZGlhT2JqZWN0IH1cbiJdfQ== */"));
exports.Media = Media;
var Content = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "en55jn1"
} : {
  target: "en55jn1",
  label: "Content"
})(process.env.NODE_ENV === "production" ? {
  name: "277wu0",
  styles: "flex:1;width:100%"
} : {
  name: "277wu0",
  styles: "flex:1;width:100%",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL01lZGlhT2JqZWN0LmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFpQjBCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL01lZGlhT2JqZWN0LmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5pbXBvcnQgeyBCUkVBS1BPSU5UUywgbXEgfSBmcm9tICcuLi91dGlscydcblxuY29uc3QgTWVkaWEgPSBzdHlsZWQuZGl2YFxuICBtYXgtd2lkdGg6IDEwMCU7XG5cbiAgaW1nLFxuICBzdmcge1xuICAgIG1heC13aWR0aDogMTAwJTtcbiAgfVxuXG4gICR7bXEoQlJFQUtQT0lOVFMuc20pfSB7XG4gICAgbWF4LXdpZHRoOiAzMCU7XG4gIH1cbmBcblxuY29uc3QgQ29udGVudCA9IHN0eWxlZC5kaXZgXG4gIGZsZXg6IDE7XG4gIHdpZHRoOiAxMDAlO1xuYFxuXG5jb25zdCBNZWRpYU9iamVjdCA9IHN0eWxlZC5kaXZgXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBmbGV4LXN0YXJ0O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBnYXA6IDFyZW07XG5cbiAgJHttcShCUkVBS1BPSU5UUy5zbSl9IHtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ2FwOiAycmVtO1xuICB9XG5gXG5cbmV4cG9ydCB7IENvbnRlbnQsIE1lZGlhLCBNZWRpYU9iamVjdCB9XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
exports.Content = Content;
var MediaObject = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "en55jn0"
} : {
  target: "en55jn0",
  label: "MediaObject"
})("display:flex;align-items:flex-start;flex-direction:column;gap:1rem;", (0, _utils.mq)(_utils.BREAKPOINTS.sm), "{flex-direction:row;align-items:center;gap:2rem;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL01lZGlhT2JqZWN0LmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFzQjhCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL01lZGlhT2JqZWN0LmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5pbXBvcnQgeyBCUkVBS1BPSU5UUywgbXEgfSBmcm9tICcuLi91dGlscydcblxuY29uc3QgTWVkaWEgPSBzdHlsZWQuZGl2YFxuICBtYXgtd2lkdGg6IDEwMCU7XG5cbiAgaW1nLFxuICBzdmcge1xuICAgIG1heC13aWR0aDogMTAwJTtcbiAgfVxuXG4gICR7bXEoQlJFQUtQT0lOVFMuc20pfSB7XG4gICAgbWF4LXdpZHRoOiAzMCU7XG4gIH1cbmBcblxuY29uc3QgQ29udGVudCA9IHN0eWxlZC5kaXZgXG4gIGZsZXg6IDE7XG4gIHdpZHRoOiAxMDAlO1xuYFxuXG5jb25zdCBNZWRpYU9iamVjdCA9IHN0eWxlZC5kaXZgXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBmbGV4LXN0YXJ0O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBnYXA6IDFyZW07XG5cbiAgJHttcShCUkVBS1BPSU5UUy5zbSl9IHtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ2FwOiAycmVtO1xuICB9XG5gXG5cbmV4cG9ydCB7IENvbnRlbnQsIE1lZGlhLCBNZWRpYU9iamVjdCB9XG4iXX0= */"));
exports.MediaObject = MediaObject;
import React, { useCallback, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { SurveyHeader, SurveyPreview, Title } from '@fwl/shared'

import AnonymityMessage from '../components/AnonymityMessage'
import Layout from '../components/Layout'
import { useStore } from '../useStore'

const PulseRequestView = () => {
  const { code } = useParams()

  const requestPulseRequest = useStore((store) => store.requestPulseRequest)
  const setPulseRequestAnswers = useStore(
    (store) => store.setPulseRequestAnswers
  )
  const pulseRequest = useStore((store) => store.pulseRequest)

  const [currentResponse, setCurrentResponse] = useState(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const initializeRequest = async () => {
      const request = await requestPulseRequest(code)
      const [initialResponse] = request.responses

      setCurrentResponse(initialResponse)
    }

    initializeRequest()
  }, [code, requestPulseRequest])

  const { responses = [] } = pulseRequest || {}

  const getQuestionParentResponse = useCallback(
    (questionId) => {
      const [parentResponseId] = questionId.split('/')
      const parentResponse = responses.find(
        (response) => String(response.id) === parentResponseId
      )

      return parentResponse
    },
    [responses]
  )

  const setQuestionParentResponse = useCallback(
    (questionId) => {
      const parentResponse = getQuestionParentResponse(questionId)

      if (parentResponse) {
        setCurrentResponse(parentResponse)
      }
    },
    [getQuestionParentResponse]
  )

  const submitThoughts = async (pulseAnswers) => {
    const answersByResponseId = Object.entries(pulseAnswers).reduce(
      (answersMap, [answerId, questionAnswer]) => {
        const [reponseId, questionId] = answerId.split('/')

        answersMap[reponseId] = answersMap[reponseId] || {}
        answersMap[reponseId][questionId] = questionAnswer

        return answersMap
      },
      {}
    )

    setIsSubmitting(true)
    await setPulseRequestAnswers(code, answersByResponseId)
    await requestPulseRequest(code)
    setIsSubmitting(false)
  }

  const normalizeQuestions = (questions, responseId) =>
    questions.map((question) => {
      const { id, ...questionBody } = question.body[0]

      return {
        id: `${responseId}/${id}`,
        ...questionBody,
      }
    })

  if (pulseRequest === null) {
    return 'Loading...'
  }

  const questions = responses
    .map((response) => normalizeQuestions(response.questions, response.id))
    .flat()

  return (
    <Layout>
      {pulseRequest.answered || currentResponse === null ? (
        <div className="done">
          <Title title="Done!" />
          <p>All done! Thank you for sharing. See you again soon!</p>
        </div>
      ) : (
        <SurveyPreview
          questions={questions}
          templateContext={currentResponse.templateContext}
          pre={({ stepNumber, totalSteps }) => {
            return (
              <SurveyHeader
                title="Pulse question"
                stepNumber={stepNumber}
                total={totalSteps}
                team={currentResponse.team}
                templateContext={currentResponse.templateContext}
              />
            )
          }}
          post={<AnonymityMessage />}
          onQuestionRender={setQuestionParentResponse}
          submit={submitThoughts}
          isSubmitting={isSubmitting}
        />
      )}
    </Layout>
  )
}

export default PulseRequestView

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["children", "extra"];
var StyledSectionTitle = (0, _base["default"])("nav", process.env.NODE_ENV === "production" ? {
  target: "e1rkhhwq0"
} : {
  target: "e1rkhhwq0",
  label: "StyledSectionTitle"
})("margin-bottom:1.4rem;display:flex;align-items:center;.extra{margin-left:auto;}.title{font-size:1.3rem;font-weight:", function (_ref) {
  var theme = _ref.theme;
  return theme.typography.normal.medium;
}, ";}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL1NlY3Rpb25UaXRsZS5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSXFDIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL1NlY3Rpb25UaXRsZS5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBTdHlsZWRTZWN0aW9uVGl0bGUgPSBzdHlsZWQubmF2YFxuICBtYXJnaW4tYm90dG9tOiAxLjRyZW07XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgLmV4dHJhIHtcbiAgICBtYXJnaW4tbGVmdDogYXV0bztcbiAgfVxuXG4gIC50aXRsZSB7XG4gICAgZm9udC1zaXplOiAxLjNyZW07XG4gICAgZm9udC13ZWlnaHQ6ICR7KHsgdGhlbWUgfSkgPT4gdGhlbWUudHlwb2dyYXBoeS5ub3JtYWwubWVkaXVtfTtcbiAgfVxuYFxuXG5jb25zdCBTZWN0aW9uVGl0bGUgPSAoeyBjaGlsZHJlbiwgZXh0cmEsIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZFNlY3Rpb25UaXRsZSBjbGFzc05hbWU9XCJjb21wb25lbnQtLXNlY3Rpb24tdGl0bGVcIiB7Li4ucHJvcHN9PlxuICAgIDxkaXYgY2xhc3NOYW1lPVwidGl0bGVcIj57Y2hpbGRyZW59PC9kaXY+XG4gICAgPGRpdiBjbGFzc05hbWU9XCJleHRyYVwiPntleHRyYX08L2Rpdj5cbiAgPC9TdHlsZWRTZWN0aW9uVGl0bGU+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IFNlY3Rpb25UaXRsZVxuIl19 */"));

var SectionTitle = function SectionTitle(_ref2) {
  var children = _ref2.children,
      extra = _ref2.extra,
      props = (0, _objectWithoutProperties2["default"])(_ref2, _excluded);
  return (0, _react2.jsx)(StyledSectionTitle, (0, _extends2["default"])({
    className: "component--section-title"
  }, props), (0, _react2.jsx)("div", {
    className: "title"
  }, children), (0, _react2.jsx)("div", {
    className: "extra"
  }, extra));
};

var _default = SectionTitle;
exports["default"] = _default;
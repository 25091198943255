"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireWildcard(require("react"));

var _Button = _interopRequireDefault(require("./Button"));

var _PopoverBase = _interopRequireDefault(require("./PopoverBase"));

var _react2 = require("@emotion/react");

var _excluded = ["children"];

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

var StyledPopoverMenu = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1ej40k30"
} : {
  target: "e1ej40k30",
  label: "StyledPopoverMenu"
})(process.env.NODE_ENV === "production" ? {
  name: "1s99l0e",
  styles: "background:#fff;box-shadow:1px 1px 5px #0001;padding:1rem;white-space:nowrap"
} : {
  name: "1s99l0e",
  styles: "background:#fff;box-shadow:1px 1px 5px #0001;padding:1rem;white-space:nowrap",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL1BvcG92ZXJNZW51LmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFPb0MiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvUG9wb3Zlck1lbnUuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0LCB7IHVzZVN0YXRlIH0gZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5pbXBvcnQgQnV0dG9uIGZyb20gJy4vQnV0dG9uJ1xuaW1wb3J0IFBvcG92ZXJCYXNlIGZyb20gJy4vUG9wb3ZlckJhc2UnXG5cbmNvbnN0IFN0eWxlZFBvcG92ZXJNZW51ID0gc3R5bGVkLmRpdmBcbiAgYmFja2dyb3VuZDogI2ZmZjtcbiAgYm94LXNoYWRvdzogMXB4IDFweCA1cHggIzAwMDE7XG4gIHBhZGRpbmc6IDFyZW07XG5cbiAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbmBcblxuY29uc3QgUG9wb3Zlck1lbnUgPSAoeyBjaGlsZHJlbiwgLi4ucHJvcHMgfSkgPT4ge1xuICBjb25zdCBbbWVudSwgc2V0TWVudV0gPSB1c2VTdGF0ZShmYWxzZSlcblxuICBjb25zdCBDbGlja0FjdHVhdG9yID0gKFxuICAgIDxCdXR0b24gb25DbGljaz17KCkgPT4gc2V0TWVudSghbWVudSl9IGFjdGl2ZT17bWVudX0+XG4gICAgICAuLi5cbiAgICA8L0J1dHRvbj5cbiAgKVxuXG4gIHJldHVybiAoXG4gICAgPFBvcG92ZXJCYXNlXG4gICAgICB2aXNpYmxlPXttZW51fVxuICAgICAgYWN0dWF0b3I9e0NsaWNrQWN0dWF0b3J9XG4gICAgICBvbkNsb3NlPXsoKSA9PiBzZXRNZW51KGZhbHNlKX1cbiAgICA+XG4gICAgICA8U3R5bGVkUG9wb3Zlck1lbnUgY2xhc3NOYW1lPVwiXCIgey4uLnByb3BzfSBvbkNsaWNrPXsoKSA9PiBzZXRNZW51KGZhbHNlKX0+XG4gICAgICAgIHtjaGlsZHJlbn1cbiAgICAgIDwvU3R5bGVkUG9wb3Zlck1lbnU+XG4gICAgPC9Qb3BvdmVyQmFzZT5cbiAgKVxufVxuXG5Qb3BvdmVyTWVudS5kaXNwbGF5TmFtZSA9ICdQb3BvdmVyTWVudSdcblxuZXhwb3J0IGRlZmF1bHQgUG9wb3Zlck1lbnVcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var PopoverMenu = function PopoverMenu(_ref) {
  var children = _ref.children,
      props = (0, _objectWithoutProperties2["default"])(_ref, _excluded);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      menu = _useState2[0],
      setMenu = _useState2[1];

  var ClickActuator = (0, _react2.jsx)(_Button["default"], {
    onClick: function onClick() {
      return setMenu(!menu);
    },
    active: menu
  }, "...");
  return (0, _react2.jsx)(_PopoverBase["default"], {
    visible: menu,
    actuator: ClickActuator,
    onClose: function onClose() {
      return setMenu(false);
    }
  }, (0, _react2.jsx)(StyledPopoverMenu, (0, _extends2["default"])({
    className: ""
  }, props, {
    onClick: function onClick() {
      return setMenu(false);
    }
  }), children));
};

PopoverMenu.displayName = 'PopoverMenu';
var _default = PopoverMenu;
exports["default"] = _default;
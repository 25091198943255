import React from 'react'

import { Route, Switch } from 'react-router-dom'

import PulseRequestView from '../views/PulseRequestView'
import SurveyResponseView from '../views/SurveyResponseView'

const AppRouter = () => (
  <>
    <Switch>
      <Route path="/pulse/responses/:code">
        <PulseRequestView />
      </Route>
      <Route path="/surveys/responses/:code">
        <SurveyResponseView />
      </Route>
    </Switch>
  </>
)

export default AppRouter

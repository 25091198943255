"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var getIsConditionMet = function getIsConditionMet(_ref) {
  var condition = _ref.condition,
      getDependencyAnswer = _ref.getDependencyAnswer;
  var target = condition.target,
      operator = condition.operator,
      value = condition.value;
  var dependencyQuestionAnswer = getDependencyAnswer(target);

  if (!dependencyQuestionAnswer) {
    return false;
  }

  if (operator === 'filled-in' && dependencyQuestionAnswer) {
    return true;
  }

  if (operator === 'equal' && dependencyQuestionAnswer === value) {
    return true;
  }

  if (operator === 'not-equal' && dependencyQuestionAnswer !== value) {
    return true;
  }

  if (operator === 'greater-than' && dependencyQuestionAnswer > value) {
    return true;
  }

  if (operator === 'greater-than-or-equal' && dependencyQuestionAnswer >= value) {
    return true;
  }

  if (operator === 'less-than' && dependencyQuestionAnswer < value) {
    return true;
  }

  if (operator === 'less-than-or-equal' && dependencyQuestionAnswer <= value) {
    return true;
  }

  return false;
};

var getAreAllConditionsMet = function getAreAllConditionsMet(_ref2) {
  var question = _ref2.question,
      allQuestions = _ref2.allQuestions,
      surveyAnswers = _ref2.surveyAnswers;
  var _question$conditions = question.conditions,
      conditions = _question$conditions === void 0 ? [] : _question$conditions;

  var getDependencyAnswer = function getDependencyAnswer(conditionTargetQuestionId) {
    var dependencyQuestion = allQuestions.find(function (question) {
      return question.id === conditionTargetQuestionId;
    });

    if (!dependencyQuestion) {
      return null;
    }

    return surveyAnswers[dependencyQuestion.id];
  };

  if (conditions.length === 0) {
    return true;
  }

  var areAllConditionsMet = conditions.every(function (condition) {
    return getIsConditionMet({
      condition: condition,
      getDependencyAnswer: getDependencyAnswer
    });
  });
  return areAllConditionsMet;
};

var _default = getAreAllConditionsMet;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledGroupWork = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "efifn2f0"
} : {
  target: "efifn2f0",
  label: "StyledGroupWork"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0dyb3VwV29yay5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSWtDIiwiZmlsZSI6Ii4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL0dyb3VwV29yay5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBTdHlsZWRHcm91cFdvcmsgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBHcm91cFdvcmsgPSAoeyBzaXplID0gJzEuNXJlbScsIG9uQ2xpY2ssIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZEdyb3VwV29ya1xuICAgIGNsYXNzTmFtZT1cImNvbXBvbmVudHMtLWljb25cIlxuICAgIHNpemU9e3NpemV9XG4gICAgY2xpY2thYmxlPXt0eXBlb2Ygb25DbGljayAhPT0gJ3VuZGVmaW5lZCd9XG4gICAgb25DbGljaz17b25DbGlja31cbiAgICB7Li4ucHJvcHN9XG4gID5cbiAgICA8c3ZnIHZpZXdCb3g9XCIwIDAgMjQgMjRcIiBmaWxsPVwiY3VycmVudENvbG9yXCI+XG4gICAgICA8cGF0aCBkPVwiTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6TTggMTcuNWMtMS4zOCAwLTIuNS0xLjEyLTIuNS0yLjVzMS4xMi0yLjUgMi41LTIuNSAyLjUgMS4xMiAyLjUgMi41LTEuMTIgMi41LTIuNSAyLjV6TTkuNSA4YzAtMS4zOCAxLjEyLTIuNSAyLjUtMi41czIuNSAxLjEyIDIuNSAyLjUtMS4xMiAyLjUtMi41IDIuNVM5LjUgOS4zOCA5LjUgOHptNi41IDkuNWMtMS4zOCAwLTIuNS0xLjEyLTIuNS0yLjVzMS4xMi0yLjUgMi41LTIuNSAyLjUgMS4xMiAyLjUgMi41LTEuMTIgMi41LTIuNSAyLjV6XCIgLz5cbiAgICA8L3N2Zz5cbiAgPC9TdHlsZWRHcm91cFdvcms+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IEdyb3VwV29ya1xuIl19 */"));

var GroupWork = function GroupWork(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledGroupWork, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM8 17.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM9.5 8c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8zm6.5 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
  })));
};

var _default = GroupWork;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = require("@emotion/react");

var Message = (0, _base["default"])("p", process.env.NODE_ENV === "production" ? {
  target: "exwivo10"
} : {
  target: "exwivo10",
  label: "Message"
})("color:", function (_ref) {
  var theme = _ref.theme;
  return theme.palette.red.base;
}, ";margin-top:6px;" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0Vycm9yTWVzc2FnZS5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRXdCIiwiZmlsZSI6Ii4uLy4uL3NyYy9jb21wb25lbnRzL0Vycm9yTWVzc2FnZS5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgTWVzc2FnZSA9IHN0eWxlZC5wYFxuICBjb2xvcjogJHsoeyB0aGVtZSB9KSA9PiB0aGVtZS5wYWxldHRlLnJlZC5iYXNlfTtcbiAgbWFyZ2luLXRvcDogNnB4O1xuYFxuXG5jb25zdCBFcnJvck1lc3NhZ2UgPSAoeyBlcnJvcnMsIGZpZWxkTmFtZSB9KSA9PiB7XG4gIGNvbnN0IHsgbWVzc2FnZSB9ID0gZXJyb3JzW2ZpZWxkTmFtZV0gfHwge31cblxuICBpZiAoIW1lc3NhZ2UpIHtcbiAgICByZXR1cm4gbnVsbFxuICB9XG5cbiAgcmV0dXJuIDxNZXNzYWdlPnttZXNzYWdlfTwvTWVzc2FnZT5cbn1cblxuZXhwb3J0IGRlZmF1bHQgRXJyb3JNZXNzYWdlXG4iXX0= */"));

var ErrorMessage = function ErrorMessage(_ref2) {
  var errors = _ref2.errors,
      fieldName = _ref2.fieldName;

  var _ref3 = errors[fieldName] || {},
      message = _ref3.message;

  if (!message) {
    return null;
  }

  return (0, _react.jsx)(Message, null, message);
};

var _default = ErrorMessage;
exports["default"] = _default;
import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { SurveyHeader, SurveyPreview, Title } from '@fwl/shared'

import AnonymityMessage from '../components/AnonymityMessage'
import Layout from '../components/Layout'
import { useStore } from '../useStore'

const ResponseView = () => {
  const { code } = useParams()

  const requestSurveyResponse = useStore((store) => store.requestSurveyResponse)
  const setSurveyResponseAnswer = useStore(
    (store) => store.setSurveyResponseAnswer
  )
  const surveyResponse = useStore((state) => state.surveyResponse)

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    requestSurveyResponse(code)
  }, [code, requestSurveyResponse])

  const submitSurvey = async (surveyAnswers) => {
    setIsSubmitting(true)
    await setSurveyResponseAnswer(code, surveyAnswers)
    setIsSubmitting(false)
  }

  if (surveyResponse === null) {
    return 'Loading...'
  }

  return (
    <Layout>
      {surveyResponse.answeredAt !== null ? (
        <div className="done">
          <Title title="Done!" />
          <p>All done! Thank you for sharing. See you again soon!</p>
        </div>
      ) : (
        <SurveyPreview
          questions={surveyResponse.questions}
          templateContext={surveyResponse.templateContext}
          pre={({ stepNumber, totalSteps }) => {
            return (
              <SurveyHeader
                stepNumber={stepNumber}
                total={totalSteps}
                title={surveyResponse.title}
                team={surveyResponse.team}
                templateContext={surveyResponse.templateContext}
              />
            )
          }}
          post={<AnonymityMessage />}
          submit={submitSurvey}
          isSubmitting={isSubmitting}
        />
      )}
    </Layout>
  )
}

export default ResponseView

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledSpeed = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1dv43tz0"
} : {
  target: "e1dv43tz0",
  label: "StyledSpeed"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL1NwZWVkLmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJOEIiLCJmaWxlIjoiLi4vLi4vLi4vc3JjL2NvbXBvbmVudHMvaWNvbnMvU3BlZWQuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkU3BlZWQgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBTcGVlZCA9ICh7IHNpemUgPSAnMS41cmVtJywgb25DbGljaywgLi4ucHJvcHMgfSkgPT4gKFxuICA8U3R5bGVkU3BlZWRcbiAgICBjbGFzc05hbWU9XCJjb21wb25lbnRzLS1pY29uXCJcbiAgICBzaXplPXtzaXplfVxuICAgIGNsaWNrYWJsZT17dHlwZW9mIG9uQ2xpY2sgIT09ICd1bmRlZmluZWQnfVxuICAgIG9uQ2xpY2s9e29uQ2xpY2t9XG4gICAgey4uLnByb3BzfVxuICA+XG4gICAgPHN2ZyB2aWV3Qm94PVwiMCAwIDI0IDI0XCIgZmlsbD1cImN1cnJlbnRDb2xvclwiPlxuICAgICAgPHBhdGggZD1cIk0yMC4zOCA4LjU3bC0xLjIzIDEuODVhOCA4IDAgMDEtLjIyIDcuNThINS4wN0E4IDggMCAwMTE1LjU4IDYuODVsMS44NS0xLjIzQTEwIDEwIDAgMDAzLjM1IDE5YTIgMiAwIDAwMS43MiAxaDEzLjg1YTIgMiAwIDAwMS43NC0xIDEwIDEwIDAgMDAtLjI3LTEwLjQ0elwiIC8+XG4gICAgICA8cGF0aCBkPVwiTTEwLjU5IDE1LjQxYTIgMiAwIDAwMi44MyAwbDUuNjYtOC40OS04LjQ5IDUuNjZhMiAyIDAgMDAwIDIuODN6XCIgLz5cbiAgICA8L3N2Zz5cbiAgPC9TdHlsZWRTcGVlZD5cbilcblxuZXhwb3J0IGRlZmF1bHQgU3BlZWRcbiJdfQ== */"));

var Speed = function Speed(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledSpeed, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M20.38 8.57l-1.23 1.85a8 8 0 01-.22 7.58H5.07A8 8 0 0115.58 6.85l1.85-1.23A10 10 0 003.35 19a2 2 0 001.72 1h13.85a2 2 0 001.74-1 10 10 0 00-.27-10.44z"
  }), (0, _react2.jsx)("path", {
    d: "M10.59 15.41a2 2 0 002.83 0l5.66-8.49-8.49 5.66a2 2 0 000 2.83z"
  })));
};

var _default = Speed;
exports["default"] = _default;
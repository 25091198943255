"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var isNil = function isNil(value) {
  return value == null;
};

var _default = isNil;
exports["default"] = _default;
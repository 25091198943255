import React from 'react'

import create from 'zustand'
import createContext from 'zustand/context'

import { createClient, useAuth } from '@fwl/shared'

const { Provider, useStore } = createContext()

const createStore = (client) =>
  create((set) => ({
    pulseRequest: null,
    surveyResponse: null,

    requestPulseRequest: async (code) => {
      const pulseRequest = await client.getPulseRequestByCode(code)
      set({ pulseRequest })
      return pulseRequest
    },
    setPulseRequestAnswers: async (code, answers) => {
      const pulseRequest = await client.setPulseRequestAnswers(code, answers)
      set({ pulseRequest })
    },

    requestSurveyResponse: async (code) => {
      const surveyResponse = await client.getSurveyResponseByCode(code)
      set({ surveyResponse })
    },
    setSurveyResponseAnswer: async (code, answers) => {
      const surveyResponse = await client.setSurveyResponseAnswer(code, answers)
      set({ surveyResponse })
    },
  }))

const StoreProvider = ({ children, baseURL }) => {
  const { token, logOut } = useAuth()
  const client = createClient({ token, baseURL, logOut })

  return <Provider createStore={() => createStore(client)}>{children}</Provider>
}

export { StoreProvider, useStore }

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["size", "onClick"];
var StyledPeople = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "eiqdk0j0"
} : {
  target: "eiqdk0j0",
  label: "StyledPeople"
})("width:", function (_ref) {
  var size = _ref.size;
  return size;
}, ";height:", function (_ref2) {
  var size = _ref2.size;
  return size;
}, ";cursor:", function (_ref3) {
  var clickable = _ref3.clickable;
  return clickable ? 'pointer' : 'inherit';
}, ";display:inline-block;svg{width:100%;height:100%;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL1Blb3BsZS5qc3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSStCIiwiZmlsZSI6Ii4uLy4uLy4uL3NyYy9jb21wb25lbnRzL2ljb25zL1Blb3BsZS5qc3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5cbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBTdHlsZWRQZW9wbGUgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogJHsoeyBzaXplIH0pID0+IHNpemV9O1xuICBoZWlnaHQ6ICR7KHsgc2l6ZSB9KSA9PiBzaXplfTtcbiAgY3Vyc29yOiAkeyh7IGNsaWNrYWJsZSB9KSA9PiAoY2xpY2thYmxlID8gJ3BvaW50ZXInIDogJ2luaGVyaXQnKX07XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcblxuICBzdmcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgfVxuYFxuXG5jb25zdCBQZW9wbGUgPSAoeyBzaXplID0gJzEuNXJlbScsIG9uQ2xpY2ssIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZFBlb3BsZVxuICAgIGNsYXNzTmFtZT1cImNvbXBvbmVudHMtLWljb25cIlxuICAgIHNpemU9e3NpemV9XG4gICAgY2xpY2thYmxlPXt0eXBlb2Ygb25DbGljayAhPT0gJ3VuZGVmaW5lZCd9XG4gICAgb25DbGljaz17b25DbGlja31cbiAgICB7Li4ucHJvcHN9XG4gID5cbiAgICA8c3ZnIHZpZXdCb3g9XCIwIDAgMjQgMjRcIiBmaWxsPVwiY3VycmVudENvbG9yXCI+XG4gICAgICA8cGF0aCBkPVwiTTE2IDExYzEuNjYgMCAyLjk5LTEuMzQgMi45OS0zUzE3LjY2IDUgMTYgNWMtMS42NiAwLTMgMS4zNC0zIDNzMS4zNCAzIDMgM3ptLTggMGMxLjY2IDAgMi45OS0xLjM0IDIuOTktM1M5LjY2IDUgOCA1QzYuMzQgNSA1IDYuMzQgNSA4czEuMzQgMyAzIDN6bTAgMmMtMi4zMyAwLTcgMS4xNy03IDMuNVYxOWgxNHYtMi41YzAtMi4zMy00LjY3LTMuNS03LTMuNXptOCAwYy0uMjkgMC0uNjIuMDItLjk3LjA1IDEuMTYuODQgMS45NyAxLjk3IDEuOTcgMy40NVYxOWg2di0yLjVjMC0yLjMzLTQuNjctMy41LTctMy41elwiIC8+XG4gICAgPC9zdmc+XG4gIDwvU3R5bGVkUGVvcGxlPlxuKVxuXG5leHBvcnQgZGVmYXVsdCBQZW9wbGVcbiJdfQ== */"));

var People = function People(_ref4) {
  var _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? '1.5rem' : _ref4$size,
      onClick = _ref4.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref4, _excluded);
  return (0, _react2.jsx)(StyledPeople, (0, _extends2["default"])({
    className: "components--icon",
    size: size,
    clickable: typeof onClick !== 'undefined',
    onClick: onClick
  }, props), (0, _react2.jsx)("svg", {
    viewBox: "0 0 24 24",
    fill: "currentColor"
  }, (0, _react2.jsx)("path", {
    d: "M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
  })));
};

var _default = People;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["children", "extra"];
var StyledPanelTitle = (0, _base["default"])("nav", process.env.NODE_ENV === "production" ? {
  target: "e1uhyjdd0"
} : {
  target: "e1uhyjdd0",
  label: "StyledPanelTitle"
})("margin-bottom:1.4rem;display:flex;align-items:center;.extra{margin-left:auto;display:flex;>*{margin-left:0.5rem;}}.title{font-size:1.2rem;font-weight:", function (_ref) {
  var theme = _ref.theme;
  return theme.typography.normal.bold;
}, ";}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL1BhbmVsVGl0bGUuanN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUltQyIsImZpbGUiOiIuLi8uLi9zcmMvY29tcG9uZW50cy9QYW5lbFRpdGxlLmpzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcblxuaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5cbmNvbnN0IFN0eWxlZFBhbmVsVGl0bGUgPSBzdHlsZWQubmF2YFxuICBtYXJnaW4tYm90dG9tOiAxLjRyZW07XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgLmV4dHJhIHtcbiAgICBtYXJnaW4tbGVmdDogYXV0bztcbiAgICBkaXNwbGF5OiBmbGV4O1xuXG4gICAgPiAqIHtcbiAgICAgIG1hcmdpbi1sZWZ0OiAwLjVyZW07XG4gICAgfVxuICB9XG5cbiAgLnRpdGxlIHtcbiAgICBmb250LXNpemU6IDEuMnJlbTtcbiAgICBmb250LXdlaWdodDogJHsoeyB0aGVtZSB9KSA9PiB0aGVtZS50eXBvZ3JhcGh5Lm5vcm1hbC5ib2xkfTtcbiAgfVxuYFxuXG5jb25zdCBQYW5lbFRpdGxlID0gKHsgY2hpbGRyZW4sIGV4dHJhLCAuLi5wcm9wcyB9KSA9PiAoXG4gIDxTdHlsZWRQYW5lbFRpdGxlIGNsYXNzTmFtZT1cImNvbXBvbmVudC0tcGFuZWwtdGl0bGVcIiB7Li4ucHJvcHN9PlxuICAgIDxkaXYgY2xhc3NOYW1lPVwidGl0bGVcIj57Y2hpbGRyZW59PC9kaXY+XG4gICAgPGRpdiBjbGFzc05hbWU9XCJleHRyYVwiPntleHRyYX08L2Rpdj5cbiAgPC9TdHlsZWRQYW5lbFRpdGxlPlxuKVxuXG5leHBvcnQgZGVmYXVsdCBQYW5lbFRpdGxlXG4iXX0= */"));

var PanelTitle = function PanelTitle(_ref2) {
  var children = _ref2.children,
      extra = _ref2.extra,
      props = (0, _objectWithoutProperties2["default"])(_ref2, _excluded);
  return (0, _react2.jsx)(StyledPanelTitle, (0, _extends2["default"])({
    className: "component--panel-title"
  }, props), (0, _react2.jsx)("div", {
    className: "title"
  }, children), (0, _react2.jsx)("div", {
    className: "extra"
  }, extra));
};

var _default = PanelTitle;
exports["default"] = _default;
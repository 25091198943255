"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _base = _interopRequireDefault(require("@emotion/styled/base"));

var _react = _interopRequireDefault(require("react"));

var _react2 = require("@emotion/react");

var _excluded = ["children", "action"];
var StyledEmpty = (0, _base["default"])("div", process.env.NODE_ENV === "production" ? {
  target: "e1frsm3l0"
} : {
  target: "e1frsm3l0",
  label: "StyledEmpty"
})("padding:1.8rem;border-radius:0.25rem;color:", function (_ref) {
  var theme = _ref.theme;
  return theme.light.contrast;
}, ";background:#fafafa;&:not(:last-child){margin-bottom:2rem;}.content{line-height:150%;}.action{margin-top:2rem;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uL3NyYy9jb21wb25lbnRzL0VtcHR5LmpzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJOEIiLCJmaWxlIjoiLi4vLi4vc3JjL2NvbXBvbmVudHMvRW1wdHkuanN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcblxuY29uc3QgU3R5bGVkRW1wdHkgPSBzdHlsZWQuZGl2YFxuICBwYWRkaW5nOiAxLjhyZW07XG4gIGJvcmRlci1yYWRpdXM6IDAuMjVyZW07XG4gIGNvbG9yOiAkeyh7IHRoZW1lIH0pID0+IHRoZW1lLmxpZ2h0LmNvbnRyYXN0fTtcbiAgYmFja2dyb3VuZDogI2ZhZmFmYTtcblxuICAmOm5vdCg6bGFzdC1jaGlsZCkge1xuICAgIG1hcmdpbi1ib3R0b206IDJyZW07XG4gIH1cblxuICAuY29udGVudCB7XG4gICAgbGluZS1oZWlnaHQ6IDE1MCU7XG4gIH1cblxuICAuYWN0aW9uIHtcbiAgICAvLyB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgbWFyZ2luLXRvcDogMnJlbTtcbiAgfVxuYFxuXG5jb25zdCBFbXB0eSA9ICh7IGNoaWxkcmVuLCBhY3Rpb24sIC4uLnByb3BzIH0pID0+IChcbiAgPFN0eWxlZEVtcHR5IGNsYXNzTmFtZT1cIlwiIHsuLi5wcm9wc30+XG4gICAgPGRpdiBjbGFzc05hbWU9XCJjb250ZW50XCI+e2NoaWxkcmVufTwvZGl2PlxuICAgIHtCb29sZWFuKGFjdGlvbikgJiYgPGRpdiBjbGFzc05hbWU9XCJhY3Rpb25cIj57YWN0aW9ufTwvZGl2Pn1cbiAgPC9TdHlsZWRFbXB0eT5cbilcblxuRW1wdHkuZGlzcGxheU5hbWUgPSAnRW1wdHknXG5cbmV4cG9ydCBkZWZhdWx0IEVtcHR5XG4iXX0= */"));

var Empty = function Empty(_ref2) {
  var children = _ref2.children,
      action = _ref2.action,
      props = (0, _objectWithoutProperties2["default"])(_ref2, _excluded);
  return (0, _react2.jsx)(StyledEmpty, (0, _extends2["default"])({
    className: ""
  }, props), (0, _react2.jsx)("div", {
    className: "content"
  }, children), Boolean(action) && (0, _react2.jsx)("div", {
    className: "action"
  }, action));
};

Empty.displayName = 'Empty';
var _default = Empty;
exports["default"] = _default;
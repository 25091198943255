"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var getVariantStyles = function getVariantStyles(variants, variant) {
  return function (props) {
    return variants[variant][props[variant]];
  };
};

var _default = getVariantStyles;
exports["default"] = _default;
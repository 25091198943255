import styled from '@emotion/styled'

import { BREAKPOINTS, Logo, mq } from '@fwl/shared'

const Container = styled.div`
  background: ${({ theme }) => theme.dark.base};
  min-height: 100vh;
  box-sizing: border-box;

  .logo {
    display: block;
    margin: 0 auto;
    color: #444;
    max-width: 6rem;
    padding: 1rem 0;

    ${mq(BREAKPOINTS.md)} {
      max-width: 10rem;
      padding: 3rem 0;
    }
  }
`

const Content = styled.div`
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.25rem;
  max-width: 30rem;
  margin: 0 auto;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;

  @media (min-width: 480px) {
    padding: 3rem;
  }
`

const Layout = ({ children }) => {
  return (
    <Container>
      <Logo />
      <Content>{children}</Content>
    </Container>
  )
}

export default Layout

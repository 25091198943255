"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mq = exports.BREAKPOINTS = void 0;

var _theme = _interopRequireDefault(require("../theme"));

var BREAKPOINTS = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 5,
  xxxl: 6
};
exports.BREAKPOINTS = BREAKPOINTS;

var mq = function mq(breakpoint) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$isMobileFirst = _ref.isMobileFirst,
      isMobileFirst = _ref$isMobileFirst === void 0 ? true : _ref$isMobileFirst;

  var breakingPoint = isMobileFirst ? _theme["default"].spacing.breakpoints[breakpoint] : _theme["default"].spacing.breakpoints[breakpoint] - 1;
  var queryKeyword = isMobileFirst ? 'min' : 'max';
  return "@media (".concat(queryKeyword, "-width: ").concat(breakingPoint, "px)");
};

exports.mq = mq;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _AcUnit = _interopRequireDefault(require("./icons/AcUnit"));

var _AddBox = _interopRequireDefault(require("./icons/AddBox"));

var _AlarmOn = _interopRequireDefault(require("./icons/AlarmOn"));

var _Apps = _interopRequireDefault(require("./icons/Apps"));

var _Assessment = _interopRequireDefault(require("./icons/Assessment"));

var _Ballot = _interopRequireDefault(require("./icons/Ballot"));

var _Blank = _interopRequireDefault(require("./icons/Blank"));

var _Brightness = _interopRequireDefault(require("./icons/Brightness2"));

var _Brightness2 = _interopRequireDefault(require("./icons/Brightness7"));

var _BurstMode = _interopRequireDefault(require("./icons/BurstMode"));

var _CheckCircle = _interopRequireDefault(require("./icons/CheckCircle"));

var _ChevronLeft = _interopRequireDefault(require("./icons/ChevronLeft"));

var _ChevronRight = _interopRequireDefault(require("./icons/ChevronRight"));

var _Close = _interopRequireDefault(require("./icons/Close"));

var _Dashboard = _interopRequireDefault(require("./icons/Dashboard"));

var _Door = _interopRequireDefault(require("./icons/Door"));

var _Download = _interopRequireDefault(require("./icons/Download"));

var _Error = _interopRequireDefault(require("./icons/Error"));

var _Explore = _interopRequireDefault(require("./icons/Explore"));

var _Face = _interopRequireDefault(require("./icons/Face"));

var _Fingerprint = _interopRequireDefault(require("./icons/Fingerprint"));

var _GroupWork = _interopRequireDefault(require("./icons/GroupWork"));

var _HighlightOff = _interopRequireDefault(require("./icons/HighlightOff"));

var _Home = _interopRequireDefault(require("./icons/Home"));

var _Info = _interopRequireDefault(require("./icons/Info"));

var _Map = _interopRequireDefault(require("./icons/Map"));

var _Memory = _interopRequireDefault(require("./icons/Memory"));

var _MoreHoriz = _interopRequireDefault(require("./icons/MoreHoriz"));

var _MoreVert = _interopRequireDefault(require("./icons/MoreVert"));

var _OfflineBolt = _interopRequireDefault(require("./icons/OfflineBolt"));

var _People = _interopRequireDefault(require("./icons/People"));

var _Photo = _interopRequireDefault(require("./icons/Photo"));

var _PhotoSizeSelectLarge = _interopRequireDefault(require("./icons/PhotoSizeSelectLarge"));

var _Poll = _interopRequireDefault(require("./icons/Poll"));

var _Power = _interopRequireDefault(require("./icons/Power"));

var _RoomPreferences = _interopRequireDefault(require("./icons/RoomPreferences"));

var _Settings = _interopRequireDefault(require("./icons/Settings"));

var _Speed = _interopRequireDefault(require("./icons/Speed"));

var _Support = _interopRequireDefault(require("./icons/Support"));

var _Teron = _interopRequireDefault(require("./icons/Teron"));

var _ThreedRotation = _interopRequireDefault(require("./icons/ThreedRotation"));

var _Timelapse = _interopRequireDefault(require("./icons/Timelapse"));

var _TimeMarker = _interopRequireDefault(require("./icons/TimeMarker"));

var _ToggleOff = _interopRequireDefault(require("./icons/ToggleOff"));

var _ToggleOn = _interopRequireDefault(require("./icons/ToggleOn"));

var _Toys = _interopRequireDefault(require("./icons/Toys"));

var _Trash = _interopRequireDefault(require("./icons/Trash"));

var _UnfoldLess = _interopRequireDefault(require("./icons/UnfoldLess"));

var _UnfoldMore = _interopRequireDefault(require("./icons/UnfoldMore"));

var _ViewCarousel = _interopRequireDefault(require("./icons/ViewCarousel"));

var _Warning = _interopRequireDefault(require("./icons/Warning"));

var _WbSunny = _interopRequireDefault(require("./icons/WbSunny"));

var _react2 = require("@emotion/react");

var _excluded = ["icon", "size"];
var defaultProps = {
  size: '1.5rem'
};
var icons = {
  'ac-unit': _AcUnit["default"],
  'add-box': _AddBox["default"],
  'alarm-on': _AlarmOn["default"],
  apps: _Apps["default"],
  assessment: _Assessment["default"],
  ballot: _Ballot["default"],
  blank: _Blank["default"],
  'brightness-2': _Brightness["default"],
  'brightness-7': _Brightness2["default"],
  'burst-mode': _BurstMode["default"],
  'check-circle': _CheckCircle["default"],
  'chevron-left': _ChevronLeft["default"],
  'chevron-right': _ChevronRight["default"],
  close: _Close["default"],
  dashboard: _Dashboard["default"],
  door: _Door["default"],
  download: _Download["default"],
  error: _Error["default"],
  explore: _Explore["default"],
  face: _Face["default"],
  fingerprint: _Fingerprint["default"],
  'group-work': _GroupWork["default"],
  'highlight-off': _HighlightOff["default"],
  home: _Home["default"],
  info: _Info["default"],
  map: _Map["default"],
  memory: _Memory["default"],
  'more-horiz': _MoreHoriz["default"],
  'more-vert': _MoreVert["default"],
  'offline-bolt': _OfflineBolt["default"],
  people: _People["default"],
  photo: _Photo["default"],
  'photo-size_select_large': _PhotoSizeSelectLarge["default"],
  poll: _Poll["default"],
  power: _Power["default"],
  'room-preferences': _RoomPreferences["default"],
  settings: _Settings["default"],
  speed: _Speed["default"],
  support: _Support["default"],
  teron: _Teron["default"],
  'threed-rotation': _ThreedRotation["default"],
  'time-marker': _TimeMarker["default"],
  timelapse: _Timelapse["default"],
  'toggle-off': _ToggleOff["default"],
  'toggle-on': _ToggleOn["default"],
  toys: _Toys["default"],
  trash: _Trash["default"],
  'unfold-less': _UnfoldLess["default"],
  'unfold-more': _UnfoldMore["default"],
  'view-carousel': _ViewCarousel["default"],
  warning: _Warning["default"],
  'wb-sunny': _WbSunny["default"]
};

var Icon = function Icon(_ref) {
  var icon = _ref.icon,
      size = _ref.size,
      props = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  var IconComponent = typeof icon === 'undefined' ? icons.blank : icons[icon];
  return (0, _react2.jsx)(IconComponent, (0, _extends2["default"])({
    size: size
  }, props));
};

Icon.displayName = 'Icon';
Icon.defaultProps = defaultProps;
var _default = Icon;
exports["default"] = _default;